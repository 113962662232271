import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1Blue,
  colors,
  H1Blue,
  H3White,
  medWrapper,
  standardWrapper,
  Btn1White,
} from "../../styles/helpers"

import SimpleWysiwyg from "./SimpleWysiwyg"
import { Link } from "gatsby"

const ContentThenColumnsSection = ({ data }) => {
  const { content, title, columns } = data
  return (
    <ContentThenColumnsSectionStyled id="measuringOurImpact">
      <div className="wrapper">
        <div className="mainTitle">
          <h2>{title}</h2>
        </div>
        <div
          className="mainContent"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      <div className="wrapperColumns">
        {columns.map((column, index) => {
          const imageDisplay = getImage(
            column.image.localFile.childImageSharp.gatsbyImageData
          )
          const imageAlt = column.image.altText
          return (
            <Column key={index} columncolor={column.backgroundColor}>
              <div className="image">
                <GatsbyImage
                  image={imageDisplay}
                  alt={imageAlt}
                  layout="fixed"
                />
              </div>
              <div className="contentWrapper">
                <div className="colTitle">
                  <h3>{column.title}</h3>
                </div>

                <SimpleWysiwyg
                  data={{ wysiwygContent: column.wysiwyg, theme: "light" }}
                />
                {column.buttonRequired && (
                  <div className="buttonLink">
                    <Link to={`/${column.buttonSlug}`}>
                      {column.buttonText}
                    </Link>
                  </div>
                )}
              </div>
            </Column>
          )
        })}
      </div>
    </ContentThenColumnsSectionStyled>
  )
}

const ContentThenColumnsSectionStyled = styled.section`
  .wrapper {
    ${standardWrapper};
    max-width: 96rem;
  }

  .mainTitle {
    width: 100%;
    text-align: center;

    h2 {
      ${H1Blue};
    }
  }

  .mainContent {
    width: 100%;
    text-align: center;

    p {
      ${B1Blue};
    }
  }

  .wrapperColumns {
    ${medWrapper};

    @media (max-width: 767px) {
      max-width: 50rem;
      margin: 0 auto;
      padding: 0;
    }
  }
`

const Column = styled.div`
  width: 100%;
  background-color: ${props =>
    props.columncolor === "orange"
      ? colors.colorTertiary
      : props.columncolor === "red"
      ? colors.colorAccent
      : props.columncolor === "lightBlue"
      ? colors.colorSecondary
      : props.columncolor === "blue"
      ? colors.colorPrimary
      : colors.colorPrimary};

  @media (max-width: 767px) {
    margin-bottom: 5rem;
  }

  @media (min-width: 768px) {
    width: calc(33.33333333% - 1rem);
    margin: 1rem 0.5rem;
  }

  .contentWrapper {
    padding: 4rem;
  }

  .colTitle {
    h3 {
      ${H3White};
    }
  }

  .buttonLink {
    width: 100%;
    a {
      ${Btn1White};
    }
  }
`

export default ContentThenColumnsSection
