import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { B1Blue, colors, H3Blue, medWrapper } from "../../styles/helpers"

const ArticlesList = ({ posts, activeCat }) => {
  return (
    <ArticlesListStyled>
      <div className="wrapper">
        {posts.map(post => {
          if (activeCat !== "all") {
            const catIndex = post.node.categories.nodes.findIndex(
              cat => cat.slug === activeCat
            )
            if (catIndex === -1) return
          }
          const imageDisplay = getImage(
            post.node.acfPostComponents.featuredImage.localFile.childImageSharp
              .gatsbyImageData
          )
          const imageAltText = getImage(
            post.node.acfPostComponents.featuredImage.altText
          )
          const options = { year: "numeric", month: "long", day: "numeric" }
          const postDate = new Date(post.node.date).toLocaleDateString(
            undefined,
            options
          )

          return (
            <Article key={post.node.id}>
              <Link to={`/news-and-media/${post.node.slug}`}>
                <div className="articleWrap">
                  <div className="featuredImage">
                    <GatsbyImage
                      image={imageDisplay}
                      alt={imageAltText ? imageAltText : post.node.title}
                      layout="fixed"
                    />
                  </div>
                  <div className="content">
                    <div className="content__title">
                      <h2>{post.node.title}</h2>
                    </div>
                    <div className="content__excerpt">
                      <p>{post.node.acfPostComponents.excerpt}</p>
                    </div>
                    <div className="content__category">
                      <p>
                        <span>{postDate}</span> |{" "}
                        <span>{post.node.categories.nodes[0].name}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </Article>
          )
        })}
      </div>
    </ArticlesListStyled>
  )
}

const ArticlesListStyled = styled.div`
  width: 100%;

  .wrapper {
    ${medWrapper};
  }
`

const Article = styled.article`
  position: relative;
  width: calc(100%);
  display: flex;
  align-items: stretch;
  margin-bottom: 5rem;
  background-color: ${colors.colorShad};

  @media (min-width: 768px) {
    width: calc(50% - 2rem);
    margin: 1rem;
    margin-bottom: 2.5rem;
  }

  @media (min-width: 1025px) {
    width: calc(33.33333333% - 2rem);
    margin: 1rem;
  }

  a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding-bottom: 5rem;
    transition: all 0.35s ease-in-out;

    &:hover {
      box-shadow: 0rem 0.5rem 0.75rem 0.1rem rgba(0, 0, 0, 0.5);
    }

    &:focus {
      outline: 0.4rem solid #003b49;
      transition: all 0.35s ease-in-out;
    }
  }

  .content {
    padding: 3rem;

    &__title {
      width: 100%;

      h2 {
        ${H3Blue};
      }
    }

    &__excerpt {
      width: 100%;

      p {
        ${B1Blue};
      }
    }

    &__category {
      position: absolute;
      right: 3rem;
      bottom: 2rem;
      left: 3rem;

      p {
        ${B1Blue};
        margin: 0;
        text-transform: uppercase;
      }
    }
  }
`

export default ArticlesList
