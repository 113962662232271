import React from "react"
import styled from "styled-components"
import { H3Blue, standardWrapper } from "../../styles/helpers"

import ResearchContentForm from "../Forms/ResearchContentForm"

const ResearchConsentContactForm = () => {
  return (
    <ResearchConsentContactFormStyled>
      <div className="wrapper">
        <div className="title">
          <h2>Research Consent Contact Form</h2>
        </div>
        <div className="form">
          <ResearchContentForm />
        </div>
      </div>
    </ResearchConsentContactFormStyled>
  )
}

const ResearchConsentContactFormStyled = styled.div`
  .wrapper {
    ${standardWrapper};
    max-width: 80rem !important;
  }

  .title {
    width: 100%;

    h2 {
      ${H3Blue};
    }
  }

  .form {
    width: 100%;
  }
`

export default ResearchConsentContactForm
