import React, { useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import CategoriesDisplay from "./CategoriesDisplay"
import ArticlesList from "./ArticlesList"
import { Btn1Gold } from "../../styles/helpers"

const getData = graphql`
  {
    posts: allWpPost(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          id
          date
          slug
          categories {
            nodes {
              name
              slug
            }
          }
          acfPostComponents {
            excerpt
            mainContent
            featuredImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }
  }
`

const NewsArticles = () => {
  const postsData = useStaticQuery(getData)
  const posts = postsData.posts.edges
  const [activeCat, setActiveCat] = useState("all")
  return (
    <NewsArticlesStyled>
      <CategoriesDisplay posts={posts} setActiveCat={setActiveCat} />
      <ArticlesList posts={posts} activeCat={activeCat} />
      <div className="loadMore">
        <button type="button" disabled>
          Load More Articles
        </button>
      </div>
    </NewsArticlesStyled>
  )
}

const NewsArticlesStyled = styled.section`
  width: 100%;

  .loadMore {
    width: 100%;
    max-width: 50rem;
    margin: 2rem auto;
    padding: 2rem;
    text-align: center;

    button {
      ${Btn1Gold};
    }
  }
`

export default NewsArticles
