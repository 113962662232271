import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BigWrapper, H3Blue } from "../../styles/helpers"

const SideBySideImageOverContent = ({ data }) => {
  const { contentOne, contentTwo, imageOne, imageTwo } = data

  const imageOneDisplay = getImage(
    imageOne.localFile.childImageSharp.gatsbyImageData
  )
  const imageOneAlt = imageOne.altText

  const imageTwoDisplay = getImage(
    imageTwo.localFile.childImageSharp.gatsbyImageData
  )
  const imageTwoAlt = imageTwo.altText

  return (
    <SideBySideImageOverContentStyled>
      <div className="wrapper">
        <div className="block blockOne">
          <div className="block__image">
            <GatsbyImage
              image={imageOneDisplay}
              alt={imageOneAlt}
              layout="fixed"
            />
          </div>
          <div className="block__content">
            <div dangerouslySetInnerHTML={{ __html: contentOne }} />
          </div>
        </div>
        <div className="block blockTwo">
          <div className="block__image">
            <GatsbyImage
              image={imageTwoDisplay}
              alt={imageTwoAlt}
              layout="fixed"
            />
          </div>
          <div className="block__content">
            <div dangerouslySetInnerHTML={{ __html: contentTwo }} />
          </div>
        </div>
      </div>
    </SideBySideImageOverContentStyled>
  )
}

const SideBySideImageOverContentStyled = styled.div`
  .wrapper {
    ${BigWrapper};
  }

  .block {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 1rem);
    }

    &__image {
      width: 100%;
    }

    &__content {
      width: 100%;
      padding: 2rem 0;

      @media (min-width: 768px) {
        padding: 2rem 2rem;
      }

      @media (min-width: 1025px) {
        padding: 2rem 7rem;
      }

      p {
        ${H3Blue};
        text-transform: uppercase;
      }
    }
  }

  .blockOne {
    @media (min-width: 768px) {
      margin-right: 1rem;
    }
  }

  .blockTwo {
    @media (min-width: 768px) {
      margin-left: 1rem;
    }
  }
`

export default SideBySideImageOverContent
