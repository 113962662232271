import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { standardWrapper, colors, H3White, B1White } from "../../styles/helpers"

const ContentBesideSmallImage = ({ data }) => {
  const { title, image, content, blockColour, reversed } = data
  const imageDisplay = getImage(image.localFile.childImageSharp.gatsbyImageData)
  return (
    <ContentBesideSmallImageStyled bgcolor={blockColour} reversed={reversed}>
      <div className="wrapper">
        <div className="content">
          <div className="content__title">
            <h2>{title}</h2>
          </div>
          <div
            className="content__para"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        <div className="image">
          <GatsbyImage
            image={imageDisplay}
            alt={image.altText}
            layout="fullWidth"
            placeholder="blurred"
            fit="cover"
          />
        </div>
      </div>
    </ContentBesideSmallImageStyled>
  )
}

const ContentBesideSmallImageStyled = styled.section`
  .wrapper {
    ${standardWrapper};
    flex-direction: ${props => (props.reversed ? "row-reverse" : "row")};

    @media (max-width: 767px) {
      max-width: 45rem;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100%);
    padding: 2rem 4rem;
    background-color: ${props =>
      props.bgcolor === "orange"
        ? colors.colorTertiary
        : props.bgcolor === "red"
        ? colors.colorAccent
        : props.bgcolor === "lightBlue"
        ? colors.colorSecondary
        : props.bgcolor === "blue"
        ? colors.colorPrimary
        : colors.colorPrimary};

    @media (min-width: 768px) {
      width: calc(80% - 2rem);
      margin-right: ${props => (props.reversed ? "0rem" : "2rem")};
      margin-left: ${props => (props.reversed ? "2rem" : "0rem")};
    }

    &__title {
      width: 100%;
    }

    &__para {
      width: 100%;
    }

    h2 {
      ${H3White};
    }

    p {
      ${B1White};
    }

    p:last-of-type {
      margin: 0;
    }
  }

  .image {
    position: relative;
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(20%);
    }
  }
`

export default ContentBesideSmallImage
