import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { standardWrapper, B1Blue, Btn1Gold } from "../../styles/helpers"

const getData = graphql`
  {
    programCats: allWpProgramCategory {
      edges {
        node {
          slug
          name
        }
      }
    }
  }
`

const ProgramsCategoriesNavigation = ({ data }) => {
  const dataCats = useStaticQuery(getData)
  const programCats = dataCats.programCats.edges
  const { displayTheListOfProgramsCategories } = data

  if (!displayTheListOfProgramsCategories) return null

  return (
    <ProgramsCategoriesNavigationStyled>
      <div className="wrapper">
        <div className="disclaimer">
          <p>Choose one of the following:</p>
        </div>
        <div className="catlinks">
          <ul>
            {programCats.map((link, index) => {
              return (
                <li key={index}>
                  <Link to={`/programs/${link.node.slug}`}>
                    {link.node.name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </ProgramsCategoriesNavigationStyled>
  )
}

const ProgramsCategoriesNavigationStyled = styled.div`
  background-color: rgba(98, 167, 196, 0.3);

  .wrapper {
    ${standardWrapper};
  }

  .disclaimer {
    width: 100%;
    text-align: center;

    p {
      ${B1Blue};
      margin: 0;
    }
  }

  .catlinks {
    width: 100%;

    ul {
      width: 100%;
      margin: 5rem auto;
      text-align: center;

      li {
        margin-bottom: 3rem;
        a {
          ${Btn1Gold};
        }
      }
    }
  }
`

export default ProgramsCategoriesNavigation
