import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H1Blue, H2Blue, standardWrapper } from "../../styles/helpers"
import BackgroundPatternOne from "../Graphics/BackgroundPatternOne"
import SimpleWysiwyg from "./SimpleWysiwyg"

const WysiwygWithHeaderAndTitles = ({ data }) => {
  const {
    iconRequired,
    mainTitle,
    mainTitleTag,
    subTitle,
    subTitleRequired,
    topBigFontContent,
    titleIcon,
    topContentRequired,
    topGraphicRequired,
    logosRequired,
    logos,
    wysiwyg,
    sectionSize,
  } = data

  const mainTitleDisplay =
    mainTitleTag === "h1" ? (
      <h1>{mainTitle}</h1>
    ) : mainTitleTag === "h2" ? (
      <h2>{mainTitle}</h2>
    ) : mainTitleTag === "h3" ? (
      <h3>{mainTitle}</h3>
    ) : (
      <h2>{mainTitle}</h2>
    )

  const iconDisplay = getImage(
    titleIcon.localFile.childImageSharp.gatsbyImageData
  )
  const iconAlt = titleIcon.altText

  return (
    <WysiwygWithHeaderAndTitlesStyled size={sectionSize}>
      <div className="wrapper">
        <div className="titleContainer">
          <div className="mainTitle">{mainTitleDisplay}</div>
          {subTitleRequired && (
            <div className="mainSubTitle">
              <p>{subTitle}</p>
            </div>
          )}
          {iconRequired && (
            <div className="mainIcon">
              <GatsbyImage image={iconDisplay} alt={iconAlt} layout="fixed" />
            </div>
          )}
        </div>
        {topContentRequired && (
          <div
            className="bigContent"
            dangerouslySetInnerHTML={{ __html: topBigFontContent }}
          />
        )}

        {logosRequired && (
          <div className="logosDisplay">
            {logos.map((logo, index) => {
              const logoDisplay = getImage(
                logo.logo.localFile.childImageSharp.gatsbyImageData
              )
              const logoAlt = logo.logo.altText
              return (
                <div className="logosDisplay__single" key={index}>
                  <GatsbyImage
                    image={logoDisplay}
                    alt={logoAlt}
                    layout="fixed"
                  />
                </div>
              )
            })}
          </div>
        )}
        <div className="wysiwygContent">
          <SimpleWysiwyg data={{ wysiwygContent: wysiwyg }} />
        </div>
      </div>
      {topGraphicRequired && (
        <div className="backgroundPattern">
          <BackgroundPatternOne />
        </div>
      )}
    </WysiwygWithHeaderAndTitlesStyled>
  )
}

const WysiwygWithHeaderAndTitlesStyled = styled.section`
  position: relative;

  ${props => console.log(props.size === "small")}
  .wrapper {
    ${standardWrapper};
    ${props =>
      props.size === "small" ? " max-width: 80rem !important" : null};
  }

  .flexboxDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;

    .aligncenter {
      display: inline-block;
      margin: 2rem;
    }
  }

  .titleContainer {
    position: relative;
    width: 100%;
    padding-top: 7rem;
    padding-left: 9rem;
  }

  .mainIcon {
    position: absolute;
    top: 9rem;
    left: 0;
    width: 8.5rem;
  }

  .mainTitle {
    width: 100%;
    h1,
    h2,
    h3 {
      ${H1Blue};
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .mainSubTitle {
    width: 100%;
    p {
      ${H2Blue};
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .bigContent {
    margin-top: 5rem;
    margin-bottom: 3rem;

    p {
      ${H2Blue};
      margin-bottom: 0;
    }
  }

  .logosDisplay {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &__single {
      width: calc(50% - 4rem);
      margin: 2rem;
      @media (min-width: 768px) {
        width: calc(25% - 3rem);
        margin: 1.5rem;
      }
    }
  }

  .wysiwygContent {
    width: 100%;

    .wysiwygContent {
      max-width: 100%;
    }
  }

  .backgroundPattern {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 17.5rem;
    z-index: -1;
  }
`

export default WysiwygWithHeaderAndTitles
