import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { B1White, BigWrapper, colors, H3White } from "../../styles/helpers"

const SideBySideContentBlocks = ({ data }) => {
  const {
    blockColourOne,
    blockColourTwo,
    buttonRequiredOne,
    buttonRequiredTwo,
    buttonSlugOne,
    buttonSlugTwo,
    buttonTextOne,
    buttonTextTwo,
    contentOne,
    contentTwo,
    titleOne,
    titleTwo,
  } = data
  return (
    <SideBySideContentBlocksStyled
      blockonecolor={blockColourOne}
      blocktwocolor={blockColourTwo}
    >
      <div className="wrapper">
        <div className="blockOne blockSection">
          <div className="blockSection__title">
            <h2>{titleOne}</h2>
          </div>
          <div
            className="blockSection__content"
            dangerouslySetInnerHTML={{ __html: contentOne }}
          />
          {buttonRequiredOne && (
            <div className="blockSection__link">
              <Link to={`/${buttonSlugOne}`}>
                <span>&#62; </span>
                {buttonTextOne}
              </Link>
            </div>
          )}
        </div>

        <div className="blockTwo blockSection">
          <div className="blockSection__title">
            <h2>{titleTwo}</h2>
          </div>
          <div
            className="blockSection__content"
            dangerouslySetInnerHTML={{ __html: contentTwo }}
          />
          {buttonRequiredTwo && (
            <div className="blockSection__link">
              <Link to={`/${buttonSlugTwo}`}>
                <span>&#62; </span>
                {buttonTextTwo}
              </Link>
            </div>
          )}
        </div>
      </div>
    </SideBySideContentBlocksStyled>
  )
}

const SideBySideContentBlocksStyled = styled.section`
  .wrapper {
    ${BigWrapper};

    @media (max-width: 767px) {
      max-width: 50rem;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .blockSection {
    width: 100%;
    margin-bottom: 2rem;
    padding: 5rem 2.5rem;

    @media (min-width: 768px) {
      width: calc(50% - 2rem);
      margin: 1rem;
      padding: 5rem;
    }

    &__title {
      margin-bottom: 2rem;

      h2 {
        ${H3White};
      }
    }

    &__content {
      p {
        ${B1White};
      }
    }

    &__link {
      a {
        ${B1White};
        transition: all 0.3s ease-in;

        &:focus {
          box-shadow: 0 0 0 0.5rem ${colors.colorSecondary};
        }

        &:hover {
          color: ${colors.black};
        }
      }
    }
  }

  .blockOne {
    background-color: ${props =>
      props.blockonecolor === "orange"
        ? colors.colorTertiary
        : props.blockonecolor === "red"
        ? colors.colorAccent
        : props.blockonecolor === "lightBlue"
        ? colors.colorSecondary
        : props.blockonecolor === "blue"
        ? colors.colorPrimary
        : colors.colorPrimary};
  }

  .blockTwo {
    background-color: ${props =>
      props.blocktwocolor === "orange"
        ? colors.colorTertiary
        : props.blocktwocolor === "red"
        ? colors.colorAccent
        : props.blocktwocolor === "lightBlue"
        ? colors.colorSecondary
        : props.blocktwocolor === "blue"
        ? colors.colorPrimary
        : colors.colorPrimary};
  }
`

export default SideBySideContentBlocks
