import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import BGImg from "gatsby-background-image"
import { Btn1Gold, H1White, H2Orange, H2White } from "../../styles/helpers"

const HeroContentTwo = ({ data }) => {
  const {
    title,
    subTitle,
    content,
    buttonRequired,
    buttonSlug,
    buttonText,
    backgroundImage,
  } = data

  const heroImage = backgroundImage.localFile.childImageSharp.fluid

  return (
    <HeroContentTwoStyled>
      <div className="wrapper">
        <div className="heroContent">
          <div className="heroContent__titles">
            <h1>{title}</h1>
            <h2>{subTitle}</h2>
          </div>
          <div
            className="heroContent__content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {buttonRequired && (
            <div className="heroContent__link">
              <Link to={`/${buttonSlug}`}>{buttonText}</Link>
            </div>
          )}
        </div>
      </div>
      <div className="heroImage">
        <BGImg tag="div" fluid={heroImage} />
      </div>
    </HeroContentTwoStyled>
  )
}

const HeroContentTwoStyled = styled.section`
  position: relative;
  padding: 5rem 0;
  min-height: 60rem;

  @media (min-width: 768px) {
    min-height: 65rem;
  }

  @media (min-width: 1025px) {
    min-height: 75rem;
  }

  .heroContent {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2.5rem 5%;
    background-color: rgba(0, 59, 73, 0.9);
    z-index: 100;

    @media (min-width: 1025px) {
      max-width: 110rem;
      padding: 2.5rem 5%;
    }

    &__titles {
      h1 {
        ${H1White};
      }

      h2 {
        ${H2Orange};
      }
    }

    &__content {
      p {
        ${H2White};
      }
    }

    &__link {
      width: 100%;

      a {
        ${Btn1Gold};
      }
    }
  }

  .heroImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    div {
      width: 100%;
      height: 100%;
    }
  }
`

export default HeroContentTwo
