import React from "react"
import styled from "styled-components"
import BGImage from "gatsby-background-image"
import { graphql, Link, useStaticQuery } from "gatsby"
import { standardWrapper, colors, H3White, B1White } from "../../styles/helpers"

const getData = graphql`
  {
    posts: allWpPost(limit: 1) {
      edges {
        node {
          title
          slug
          acfPostComponents {
            excerpt
            featuredImage {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 7000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const LastestNewsArticle = ({ data }) => {
  const postData = useStaticQuery(getData)
  const { title, slug, acfPostComponents } = postData.posts.edges[0].node
  const { excerpt, featuredImage } = acfPostComponents
  const bgImage = featuredImage.localFile.childImageSharp.fluid

  return (
    <LastestNewsArticleStyled>
      <Link to={`/news-and-media/${slug}`}>
        <div className="wrapper">
          <div className="content">
            <div className="content__title">
              <h2>{title}</h2>
            </div>
            <div className="content__para">
              <p>{excerpt}</p>
            </div>
          </div>
          <div className="image">
            <BGImage tag="div" fluid={bgImage} alt="" />
          </div>
        </div>
      </Link>
    </LastestNewsArticleStyled>
  )
}

const LastestNewsArticleStyled = styled.section`
  .wrapper {
    ${standardWrapper};
    flex-direction: row-reverse;

    @media (max-width: 767px) {
      max-width: 45rem;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100%);
    padding: 2rem 4rem;
    background-color: ${colors.colorPrimary};

    @media (min-width: 768px) {
      width: calc(80% - 2rem);
      margin-left: 2rem;
    }

    &__title {
      width: 100%;
    }

    &__para {
      width: 100%;
    }

    h2 {
      ${H3White};
    }

    p {
      ${B1White};
    }

    p:last-of-type {
      margin: 0;
    }
  }

  .image {
    position: relative;
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(20%);
    }

    div {
      width: 100%;
      height: 100%;
    }
  }
`

export default LastestNewsArticle
