import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1Blue, H1Blue, H2Blue, medWrapper } from "../../styles/helpers"
import BackgroundPatternOne from "../Graphics/BackgroundPatternOne"

const ContentBySmallImage = ({ data }) => {
  const { title, subText, content, image, topGraphicActive } = data

  const imageDisplay = getImage(image.localFile.childImageSharp.gatsbyImageData)
  const imageAlt = image.altText

  return (
    <ContentBySmallImageStyled graphicactive={topGraphicActive}>
      <div className="wrapper">
        <div className="content">
          <div className="content__title">
            <h2>{title}</h2>
          </div>
          <div className="content__subText">
            <p>{subText}</p>
          </div>
          <div
            className="content__para"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>

        <div className="image">
          <GatsbyImage image={imageDisplay} alt={imageAlt} layout="fixed" />
        </div>
      </div>
      <div className="backgroundPattern">
        <BackgroundPatternOne />
      </div>
    </ContentBySmallImageStyled>
  )
}

const ContentBySmallImageStyled = styled.section`
  position: relative;
  padding-top: ${props => (props.graphicactive ? "7.5rem" : "0rem")};

  .wrapper {
    ${medWrapper};
    align-items: center;
  }

  .image {
    width: 100%;

    @media (max-width: 767px) {
      max-width: 20rem;
    }

    @media (min-width: 768px) {
      width: calc(20% - 5rem);
      margin-left: 5rem;
    }
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(80% - 5rem);
      margin-right: 5rem;
    }

    &__title {
      h2 {
        ${H1Blue};
      }
    }

    &__subText {
      p {
        ${H2Blue};
      }
    }

    &__para {
      p {
        ${B1Blue};
      }
    }
  }

  .backgroundPattern {
    display: ${props => (props.graphicactive ? "block" : "none")};
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 65%;
    z-index: -1;

    @media (min-width: 768px) {
      bottom: 60%;
    }
  }
`

export default ContentBySmallImage
