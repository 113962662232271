import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1Blue,
  Btn1Gold,
  colors,
  H1Blue,
  standardWrapper,
} from "../../styles/helpers"

const ContentWithImageSeventy = ({ data }) => {
  const {
    buttonRequired,
    buttonSlug,
    buttonText,
    content,
    contentRequired,
    reverse,
    title,
    image,
    titleColour,
  } = data

  const imageDisplay = getImage(image.localFile.childImageSharp.gatsbyImageData)
  const imageAlt = image.altText

  return (
    <ContentWithImageSeventyStyled flipimage={reverse} titlecolor={titleColour}>
      <div className="wrapper">
        <div className="mainContent">
          <div className="title">
            <h2>{title}</h2>
          </div>
          {contentRequired && (
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
          {buttonRequired && (
            <div className="buttonLink">
              <Link to={`/${buttonSlug}`}>{buttonText}</Link>
            </div>
          )}
        </div>
        <div className="image">
          <GatsbyImage image={imageDisplay} alt={imageAlt} layout="fixed" />
        </div>
      </div>
    </ContentWithImageSeventyStyled>
  )
}

const ContentWithImageSeventyStyled = styled.section`
  padding: 2.5rem 0;

  .wrapper {
    ${standardWrapper};
    flex-direction: ${props => (props.flipimage ? "row-reverse" : "reverse")};
    align-items: center;
  }

  .mainContent {
    width: 100%;

    @media (max-width: 767px) {
      margin-bottom: 5rem;
    }

    @media (min-width: 768px) {
      width: calc(70% - 4rem);
      margin-right: ${props => (props.flipimage ? "0rem" : "4rem")};
      margin-left: ${props => (props.flipimage ? "4rem" : "0rem")};
    }

    .title {
      width: 100%;

      h2 {
        ${H1Blue};
        color: ${props =>
          props.titlecolor === "lightBlue"
            ? colors.colorSecondary
            : colors.colorPrimary};
      }
    }

    .content {
      width: 100%;
      margin: 2rem auto;
      p {
        ${B1Blue};
      }
    }

    .buttonLink {
      width: 100%;
      a {
        ${Btn1Gold};
      }
    }
  }

  .image {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(30% - 4rem);
      margin-right: ${props => (props.flipimage ? "4rem" : "0rem")};
      margin-left: ${props => (props.flipimage ? "0rem" : "4rem")};
    }
  }
`

export default ContentWithImageSeventy
