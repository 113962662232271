import React from "react"
import styled from "styled-components"
import { B1White, colors, H1White, standardWrapper } from "../../styles/helpers"

import MailChimpForm from "../Forms/MailchimpForm"

const MailingList = ({ data }) => {
  return (
    <MailingListStyled>
      <div className="wrapper">
        <div className="title">
          <h3>{data.title}</h3>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
        <div className="mailChimpForm">
          <MailChimpForm idDifferernt={true} />
        </div>
      </div>
    </MailingListStyled>
  )
}

const MailingListStyled = styled.section`
  margin-bottom: 0.75rem;
  background-color: ${colors.colorSecondary};

  .wrapper {
    ${standardWrapper};
    max-width: 75rem !important;
  }

  .title {
    width: 100%;
    text-align: center;

    h3 {
      ${H1White};
    }
  }

  .content {
    width: 100%;
    text-align: center;

    p {
      ${B1White};
    }
  }

  .mailChimpForm {
    width: 100%;
  }
`

export default MailingList
