import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1White,
  Btn1Gold,
  colors,
  H1White,
  standardWrapper,
} from "../../styles/helpers"

const BannerCalloutIcon = ({ data }) => {
  const { title, content, buttonRequired, buttonSlug, buttonText, icon } = data
  const imageDisplay = getImage(
    icon?.localFile?.childImageSharp?.gatsbyImageData
  )
  const imageAlt = icon.altText
  return (
    <BannerCalloutIconStyled>
      <div className="wrapper">
        <div className="mainContent">
          <div className="title">
            <h2>{title}</h2>
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {buttonRequired && (
            <div className="buttonLink">
              <Link to={`/${buttonSlug}`}>{buttonText}</Link>
            </div>
          )}
        </div>
        <div className="mainIcon">
          {" "}
          <GatsbyImage image={imageDisplay} alt={imageAlt} layout="fixed" />
        </div>
      </div>
    </BannerCalloutIconStyled>
  )
}

const BannerCalloutIconStyled = styled.section`
  background-color: ${colors.colorSecondary};
  padding: 5rem 0;

  .wrapper {
    ${standardWrapper};
    flex-direction: row-reverse;
    max-width: 80rem;
  }

  .mainContent {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(85% - 3rem);
      margin-left: 3rem;
    }

    .title {
      width: 100%;

      h2 {
        ${H1White};
        margin-top: 0;
      }
    }

    .content {
      width: 100%;
      margin-bottom: 2rem;
      p {
        ${B1White};
      }
    }

    .buttonLink {
      width: 100%;

      a {
        ${Btn1Gold};
      }
    }
  }

  .mainIcon {
    width: 100%;

    @media (max-width: 767px) {
      max-width: 12.5rem;
      margin: 3rem 0 0;
    }

    @media (min-width: 768px) {
      width: calc(15%);
    }
  }
`

export default BannerCalloutIcon
