import React from "react"
import styled from "styled-components"
import { H3Blue, standardWrapper } from "../../styles/helpers"

import FamilyAssessment from "../Forms/FamilyAssessment"

const FamilyAssessmentForm = () => {
  return (
    <FamilyAssessmentFormStyled>
      <div className="wrapper">
        <div className="title">
          <h2>Family needs Assessment Intake Form</h2>
        </div>
        <div className="form">
          <FamilyAssessment />
        </div>
      </div>
    </FamilyAssessmentFormStyled>
  )
}

const FamilyAssessmentFormStyled = styled.section`
  .wrapper {
    ${standardWrapper};
    max-width: 80rem !important;
  }

  .title {
    width: 100%;
    h2 {
      ${H3Blue};
    }
  }

  .form {
    width: 100%;
  }
`

export default FamilyAssessmentForm
