import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1White, colors, H3Blue, standardWrapper } from "../../styles/helpers"

const CircleImageByContent = ({ data }) => {
  return (
    <CircleImageByContentStyled>
      <div className="wrapper">
        {data.blocks.map((block, index) => {
          const imageDisplay = getImage(
            block.image.localFile.childImageSharp.gatsbyImageData
          )
          const imageAltText = getImage(block.image.altText)
          return (
            <Block key={index} reversed={index % 2 !== 0}>
              <div className="blockImage">
                <div className="blockImage__container">
                  <GatsbyImage
                    image={imageDisplay}
                    alt={imageAltText ? imageAltText : ""}
                    layout="fixed"
                  />
                </div>
              </div>
              <div className="blockContent">
                <h3>{block.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: block.content }} />
              </div>
            </Block>
          )
        })}
      </div>
    </CircleImageByContentStyled>
  )
}

const CircleImageByContentStyled = styled.section`
  background-color: ${colors.colorSecondary};
  margin-bottom: 0.5rem;
  padding: 5rem 0;

  .wrapper {
    ${standardWrapper};
    max-width: 80rem !important;
  }
`

const Block = styled.div`
  ${props => console.log(props.reversed)}
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: ${props => (props.reversed ? "row-reverse" : "row")};
  width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;

  .blockImage {
    width: calc(100%);
    margin-bottom: 2.5rem;

    @media (min-width: 768px) {
      width: calc(33.3333333%);
      margin-bottom: 0;
    }

    &__container {
      width: 20rem;
      height: 20rem;
      margin: auto;
      border-radius: 50%;
      overflow: hidden;

      .gatsby-image-wrapper {
        img {
          width: 20rem;
          height: 20rem;
          margin: auto;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
  }

  .blockContent {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(66.66666666%);
    }
    padding-right: ${props => (props.reversed ? "2.5rem" : "0rem")};
    padding-left: ${props => (props.reversed ? "0rem" : "2.5rem")};

    h3 {
      ${H3Blue};
    }

    p {
      ${B1White};
    }
  }
`

export default CircleImageByContent
