import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import BGImg from "gatsby-background-image"
import { H1Blue, Btn1Gold, standardWrapper, H2Blue } from "../../styles/helpers"

const HeroContentThree = ({ data }) => {
  const {
    title,
    content,
    buttonRequired,
    buttonSlug,
    buttonText,
    backgroundImage,
  } = data

  const heroImage = backgroundImage.localFile.childImageSharp.fluid

  return (
    <HeroContentThreeStyled>
      <div className="heroImgWrap">
        <div className="heroImage">
          <BGImg tag="div" fluid={heroImage} />
        </div>
      </div>

      <div className="wrapper">
        <div className="heroContent">
          <div className="heroContent__titles">
            <h1>{title}</h1>
          </div>
          <div
            className="heroContent__content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {buttonRequired && (
            <div className="heroContent__link">
              <Link to={`/${buttonSlug}`}>{buttonText}</Link>
            </div>
          )}
        </div>
      </div>
    </HeroContentThreeStyled>
  )
}

const HeroContentThreeStyled = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .heroContent {
    width: 100%;
    padding: 2.5rem 0;
    z-index: 100;

    @media (min-width: 768px) {
      width: 100%;
      padding: 2.5rem 5%;
      z-index: 100;
    }

    @media (min-width: 1025px) {
      max-width: 110rem;
      padding: 2.5rem 5%;
    }

    &__titles {
      width: 100%;
      text-align: center;

      h1 {
        ${H1Blue};
      }
    }

    &__content {
      width: 100%;
      text-align: center;

      p {
        ${H2Blue};
      }
    }

    &__link {
      width: 100%;

      a {
        ${Btn1Gold};
      }
    }
  }

  .heroImgWrap {
    position: relative;
    padding: 5rem 0;
    min-height: 35rem;

    @media (min-width: 768px) {
      min-height: 40rem;
    }

    @media (min-width: 1025px) {
      min-height: 45rem;
    }
  }

  .heroImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    div {
      width: 100%;
      height: 100%;
    }
  }
`

export default HeroContentThree
