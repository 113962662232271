import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeroJustAnImage = ({ data }) => {
  const imageDisplay = getImage(
    data.image.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.image.altText
  return (
    <HeroJustAnImageStyled>
      <GatsbyImage image={imageDisplay} alt={imageAlt} layout="fixed" />
    </HeroJustAnImageStyled>
  )
}

const HeroJustAnImageStyled = styled.div`
  width: 100%;
`

export default HeroJustAnImage
