import React, { useState } from "react"
import styled from "styled-components"
import { B1Blue, Btn1Gold } from "../../styles/helpers"

import inputField from "./styles/inputField"
import checkboxField from "./styles/checkboxField"
import radioField from "./styles/radioField"

import submitToServer from "./functions/submitToServer"
import FormSuccess from "./formModals/FormSuccess"
import FormSubmit from "./formModals/FormSubmit"
import FormErrors from "./formModals/FormErrors"

const ResearchContentForm = () => {
  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    perferPhone: false,
    perferEmail: false,
    participantName: "",
    health: false,
    quality: false,
    social: false,
    economic: false,
    other: "",
    iagree: false,
  })

  const [formStatus, setFormStatus] = useState({
    submitting: false,
    errorWarnDisplay: false,
    success: false,
    errors: [],
  })

  const handleOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnCheckbox = event => {
    setFormData({
      ...formData,
      [event.target.value]: !formData[event.target.value],
    })
  }

  const handleOnRadioChange = event => {
    if (event.target.value === "perferPhone") {
      setFormData({
        ...formData,
        perferPhone: true,
        perferEmail: false,
      })
    } else {
      setFormData({
        ...formData,
        perferPhone: false,
        perferEmail: true,
      })
    }
  }

  const handleOnSubmit = async event => {
    event.preventDefault()
    setFormStatus({
      ...formStatus,
      submitting: true,
    })

    if (!formData.perferPhone && !formData.perferEmail) {
      setFormStatus({
        ...formStatus,
        errorWarnDisplay: true,
        errors: [{ idref: "perfectContactMethod" }],
      })
      return
    }

    if (!formData.iagree) {
      setFormStatus({
        ...formStatus,
        errorWarnDisplay: true,
        errors: [{ idref: "iagree" }],
      })
      return
    }

    const formDataArray = Object.entries(formData)
    const bodyFormData = new FormData()
    formDataArray.forEach(field => {
      bodyFormData.append(field[0], field[1])
    })

    const response = await submitToServer(543, bodyFormData)

    if (!response.errors) {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: false,
        success: true,
        errors: [],
      })
    } else {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: true,
        success: false,
        errors: response.errorMessages,
      })
    }
  }

  const handleErrorModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
    })
  }

  const handleSuccessModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
      errors: [],
    })

    setFormData({
      phone: "",
      email: "",
      perferPhone: false,
      perferEmail: false,
      participantName: "",
      health: false,
      quality: false,
      social: false,
      economic: false,
      other: "",
      iagree: false,
    })
  }

  return (
    <>
      <Form onSubmit={handleOnSubmit}>
        <div className="privacyStatement">
          <p>
            Every effort will be made to safeguard your contact information and
            protect your privacy.
          </p>
          <p>
            This consent to contact for future research is effective
            immediately. Your consent to be contacted can be revoked by you at
            any time. If you have questions or concerns regarding this consent,
            please contact: Leticia Janzen (email: lmjanzen@ucalgary.ca; phone:
            403-955-5736)
          </p>
        </div>
        <fieldset>
          <legend id="legend-1">Your Contact Information</legend>
          <InputField>
            <label htmlFor="phone">
              Phone Number <span className="required">(required)</span>
              <span
                className={`error-message${
                  formStatus.errors.findIndex(
                    error => error.idref === "phone"
                  ) !== -1
                    ? " error-active"
                    : ""
                }`}
              >
                You must input a phone number.
              </span>
              <input
                name="phone"
                type="text"
                value={formData.phone}
                id="phone"
                onChange={handleOnChange}
                aria-required="true"
                required
              />
            </label>
          </InputField>
          <InputField>
            <label htmlFor="email">
              Email <span className="required">(required)</span>
              <span
                className={`error-message${
                  formStatus.errors.findIndex(
                    error => error.idref === "email"
                  ) !== -1
                    ? " error-active"
                    : ""
                }`}
              >
                You must input an email.
              </span>
              <input
                name="email"
                type="email"
                value={formData.email}
                id="email"
                onChange={handleOnChange}
                aria-required="true"
                required
              />
            </label>
          </InputField>
        </fieldset>

        <fieldset>
          <legend id="legend-2">
            What is the preferred methods of contacting you.{" "}
            <span className="required">(required)</span>
          </legend>
          <RadioField>
            <ul aria-labelledby="legend-2">
              <li>
                <label htmlFor="perferPhone">
                  <span
                    className={`error-message${
                      formStatus.errors.findIndex(
                        error => error.idref === "perfectContactMethod"
                      ) !== -1
                        ? " error-active"
                        : ""
                    }`}
                  >
                    You must select a perfect contact method.
                  </span>
                  <input
                    id="perferPhone"
                    required=""
                    type="radio"
                    name="contentMethod"
                    value="perferPhone"
                    onChange={handleOnRadioChange}
                    checked={formData.perferPhone}
                  />
                  Perfer Phone
                </label>
              </li>
              <li>
                <label htmlFor="perferEmail">
                  <input
                    id="perferEmail"
                    type="radio"
                    name="contentMethod"
                    value="perferEmail"
                    onChange={handleOnRadioChange}
                    checked={formData.perferEmail}
                  />
                  Perfer Email
                </label>
              </li>
            </ul>
          </RadioField>
        </fieldset>

        <fieldset>
          <legend id="legend-3">Potential Participant Information</legend>
          <InputField>
            <label htmlFor="participantName">
              Potential Participant Name
              <span className="required">(required)</span>
              <span
                className={`error-message${
                  formStatus.errors.findIndex(
                    error => error.idref === "participantName"
                  ) !== -1
                    ? " error-active"
                    : ""
                }`}
              >
                You must input a name.
              </span>
              <input
                name="participantName"
                type="text"
                value={formData.participantName}
                id="participantName"
                onChange={handleOnChange}
                aria-required="true"
                required
              />
            </label>
          </InputField>
        </fieldset>
        <fieldset>
          <legend id="legend-4">
            Optional: Please contact me for studies about
          </legend>
          <CheckboxField>
            <ul aria-labelledby="legend-2" role="group">
              <li>
                <label htmlFor="health">
                  <input
                    id="health"
                    required=""
                    type="checkbox"
                    name="studies"
                    value="health"
                    onChange={handleOnCheckbox}
                    checked={formData.health}
                  />
                  Health and wellbeing
                </label>
              </li>
              <li>
                <label htmlFor="quality">
                  <input
                    id="quality"
                    required=""
                    type="checkbox"
                    name="studies"
                    value="quality"
                    onChange={handleOnCheckbox}
                    checked={formData.quality}
                  />
                  Quality of life
                </label>
              </li>
              <li>
                <label htmlFor="social">
                  <input
                    id="social"
                    required=""
                    type="checkbox"
                    name="studies"
                    value="social"
                    onChange={handleOnCheckbox}
                    checked={formData.social}
                  />
                  Social inclusion
                </label>
              </li>
              <li>
                <label htmlFor="economic">
                  <input
                    id="economic"
                    required=""
                    type="checkbox"
                    name="studies"
                    value="economic"
                    onChange={handleOnCheckbox}
                    checked={formData.economic}
                  />
                  Economic impact
                </label>
              </li>
            </ul>
          </CheckboxField>
          <InputField>
            <label htmlFor="other">
              Other
              <input
                name="other"
                type="text"
                value={formData.other}
                id="other"
                onChange={handleOnChange}
                aria-required="false"
                required={false}
              />
            </label>
          </InputField>
        </fieldset>

        <fieldset>
          <legend id="legend-5">
            Are you willing to be contacted by the CAH Research and Knowledge
            Translation Coordinator or a qualified research staff member
            completing research on behalf of the CAH?
          </legend>
          <CheckboxField>
            <ul aria-labelledby="legend-5" role="group">
              <li>
                <label htmlFor="iagree">
                  <span
                    className={`error-message${
                      formStatus.errors.findIndex(
                        error => error.idref === "iagree"
                      ) !== -1
                        ? " error-active"
                        : ""
                    }`}
                  >
                    you must agree before submitting form.
                  </span>
                  <input
                    id="iagree"
                    required=""
                    type="checkbox"
                    name="consent"
                    value="iagree"
                    onChange={handleOnCheckbox}
                    checked={formData.iagree}
                  />
                  Yes, I agree to be contacted by Calgary Adapted Hub
                </label>
              </li>
            </ul>
          </CheckboxField>
        </fieldset>
        <SubmitButton>
          <button disabled={!formData.iagree} type="submit">
            Submit
          </button>
        </SubmitButton>
      </Form>
      <FormSubmit isActive={formStatus.submitting} />
      <FormSuccess
        isActive={formStatus.success}
        handleClose={handleSuccessModalClose}
      />
      <FormErrors
        isActive={formStatus.errorWarnDisplay}
        handleClose={handleErrorModalClose}
      />
    </>
  )
}

const Form = styled.form`
  width: 100%;
  margin-bottom: 2.5rem;

  .privacyStatement {
    p {
      ${B1Blue};
    }
  }

  fieldset {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
  }

  legend {
    ${B1Blue};
  }
`

const InputField = styled.div`
  ${inputField};
`

const CheckboxField = styled.div`
  ${checkboxField};
`

const RadioField = styled.div`
  ${radioField};
`

const SubmitButton = styled.div`
  button {
    ${Btn1Gold};
  }
`

export default ResearchContentForm
