import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1White,
  Btn1Gold,
  colors,
  H1White,
  standardWrapper,
} from "../../styles/helpers"

import SocialLinks from "./SocialLinks"

const BannerCalloutNoText = ({ data }) => {
  const {
    title,
    buttonRequired,
    buttonSlug,
    buttonText,
    icon,
    iconRequired,
    socialMediaLinks,
  } = data
  const imageDisplay = getImage(
    icon?.localFile?.childImageSharp?.gatsbyImageData
  )
  const imageAlt = icon?.altText

  return (
    <BannerCalloutNoTextStyled>
      <div className="wrapper">
        <div className="mainContent">
          {iconRequired && (
            <div className="mainIcon">
              <div className="mainIcon__wrapper">
                <GatsbyImage
                  image={imageDisplay}
                  alt={imageAlt}
                  layout="fixed"
                />
              </div>
            </div>
          )}
          <div className="title">
            <h2>{title}</h2>
          </div>
          {buttonRequired && (
            <div className="buttonLink">
              <Link to={`/${buttonSlug}`}>{buttonText}</Link>
            </div>
          )}
          {socialMediaLinks && (
            <div className="socialIconsWrap">
              <SocialLinks />
            </div>
          )}
        </div>
      </div>
    </BannerCalloutNoTextStyled>
  )
}

const BannerCalloutNoTextStyled = styled.section`
  background-color: ${colors.colorSecondary};
  margin-bottom: 0.5rem;
  padding: 5rem 0;

  .wrapper {
    ${standardWrapper};
    flex-direction: row-reverse;
    max-width: 80rem;
  }

  .mainContent {
    width: 100%;
    text-align: center;

    .title {
      width: 100%;

      h2 {
        ${H1White};
        margin-top: 0;
      }
    }

    .content {
      width: 100%;
      margin-bottom: 2rem;
      p {
        ${B1White};
      }
    }

    .buttonLink {
      width: 100%;

      a {
        ${Btn1Gold};

        &:hover {
          background-color: ${colors.greyMed};
        }
      }
    }
  }

  .socialIconsWrap {
    width: 100%;
    max-width: 70rem;
    margin: 5rem auto 0;

    .title {
      width: 40%;
      text-align: right;
    }

    .socialIcons {
      width: 60%;
    }

    p {
      color: #fff;
    }

    li {
      a {
        svg {
          fill: #fff;
        }

        &:hover {
          svg {
            fill: #f9a11b;
          }
        }
      }
    }
  }

  .mainIcon {
    width: 100%;
    text-align: center;

    &__wrapper {
      max-width: 7.5rem;
      margin: auto;
    }
  }
`

export default BannerCalloutNoText
