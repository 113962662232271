import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H1LightBlue, medWrapper } from "../../styles/helpers"

const LightBlueTitleBesideImage = ({ data }) => {
  const { title, image } = data
  const imageDisplay = getImage(image.localFile.childImageSharp.gatsbyImageData)
  const imageAltText = getImage(image.altText)
  return (
    <LightBlueTitleBesideImageStyled>
      <div className="wrapper">
        <div className="title">
          <div dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div className="image">
          <GatsbyImage
            image={imageDisplay}
            alt={imageAltText ? imageAltText : ""}
            layout="fixed"
          />
        </div>
      </div>
    </LightBlueTitleBesideImageStyled>
  )
}

const LightBlueTitleBesideImageStyled = styled.div`
  .wrapper {
    ${medWrapper};
    align-items: center;
  }

  .title {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-right: 4rem;
      text-align: right;
    }

    p {
      ${H1LightBlue};
    }
  }

  .image {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-left: 4rem;
    }
  }
`

export default LightBlueTitleBesideImage
