import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BigWrapper, H4Blue } from "../../styles/helpers"

import BackgroundPatternOne from "../Graphics/BackgroundPatternOne"

const IconLinks = ({ data }) => {
  return (
    <IconLinksStyled>
      <div className="wrapper">
        <nav className="linkNav" role="navigation" aria-label="Icon Links">
          <ul>
            {data.links.map((link, index) => {
              const icon = getImage(
                link.icon.localFile.childImageSharp.gatsbyImageData
              )
              return (
                <li key={index}>
                  <Link to={`/${link.linkSlug}`}>
                    <span className="linkIcon">
                      <GatsbyImage
                        image={icon}
                        alt={link.linkText}
                        layout="fixed"
                      />
                    </span>
                    <span className="linkText">{link.linkText}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
      <div className="backgroundPattern">
        <BackgroundPatternOne />
      </div>
    </IconLinksStyled>
  )
}

const IconLinksStyled = styled.section`
  position: relative;
  .wrapper {
    ${BigWrapper};
  }

  .linkNav {
    width: 100%;

    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
      width: 100%;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(50% - 4rem);
        margin: 2rem;

        @media (min-width: 768px) {
          align-items: stretch;
          justify-content: center;
          width: calc(20% - 4rem);
        }

        a {
          ${H4Blue};
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          width: 100%;
          padding: 1rem;
          transition: all 0.3s ease-in;
          background-color: rgba(255, 255, 255, 0.5);
          text-align: center;
          text-transform: uppercase;

          &:focus {
            background-color: rgba(255, 255, 255, 1);
            box-shadow: 0 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
          }

          &:hover {
            background-color: rgba(255, 255, 255, 1);
            box-shadow: 0.2rem 0.5rem 0.8rem 0 rgba(0, 0, 0, 0.25);
          }

          span {
            display: block;
            width: 100%;
            text-align: center;
          }

          .linkIcon {
            width: 9rem;
            margin: 1rem auto;
          }
        }
      }
    }
  }

  .backgroundPattern {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 65%;
    z-index: -1;

    @media (min-width: 768px) {
      bottom: 50%;
    }
  }
`

export default IconLinks
