import React, { useState } from "react"
import styled from "styled-components"
import { B1Blue, Btn1Gold, colors, Nav1Blue } from "../../styles/helpers"

import checkboxField from "./styles/checkboxField"
import submitToServer from "./functions/submitToServer"
import FormSuccess from "./formModals/FormSuccess"
import FormSubmit from "./formModals/FormSubmit"
import FormErrors from "./formModals/FormErrors"

const FamilyAssessment = () => {
  const [formData, setFormData] = useState({
    parentName: "",
    email: "",
    phone: "",
    childName: "",
    childAge: "",
    childActivitiesInterested: "",
    childPastPro: "",
    childDisabilities: "",
    childAdaptations: "",
    childAide: "",
    animal: "",
    iagree: false,
  })

  const [formStatus, setFormStatus] = useState({
    submitting: false,
    errorWarnDisplay: false,
    success: false,
    errors: [],
  })

  const handleOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnCheckbox = event => {
    setFormData({
      ...formData,
      [event.target.value]: !formData[event.target.value],
    })
  }

  const handleOnSubmit = async event => {
    event.preventDefault()
    if (formData.animal.toLowerCase() !== "horse") {
      setFormStatus({
        ...formStatus,
        errorWarnDisplay: true,
        errors: [{ idref: "animal" }],
      })
      return
    }

    setFormStatus({
      ...formStatus,
      submitting: true,
    })
    const formDataArray = Object.entries(formData)
    const bodyFormData = new FormData()
    formDataArray.forEach(field => {
      bodyFormData.append(field[0], field[1])
    })

    const response = await submitToServer(6, bodyFormData)

    if (!response.errors) {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: false,
        success: true,
        errors: [],
      })
    } else {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: true,
        success: false,
        errors: response.errorMessages,
      })
    }
  }

  const handleErrorModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
    })
  }

  const handleSuccessModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
      errors: [],
    })
    setFormData({
      parentName: "",
      email: "",
      phone: "",
      childName: "",
      childAge: "",
      childActivitiesInterested: "",
      childPastPro: "",
      childDisabilities: "",
      childAdaptations: "",
      childAide: "",
      animal: "",
      iagree: false,
    })
  }

  return (
    <>
      <Form onSubmit={handleOnSubmit}>
        <fieldset>
          <legend>Parent and Child Contact Information</legend>
          <InputField>
            <label htmlFor="parentName">
              Parent Name <span className="required">(required)</span>
              <span
                className={`error-message ${
                  formStatus.errors.findIndex(
                    error => error.idref === "parentName"
                  ) !== -1 && " error-active"
                }`}
              >
                You must input a name.
              </span>
              <input
                name="parentName"
                type="text"
                value={formData.parentName}
                id="parentName"
                onChange={handleOnChange}
                aria-required="true"
                required
              />
            </label>
          </InputField>
          <InputField>
            <label htmlFor="email">
              Parent email <span className="required">(required)</span>
              <span
                className={`error-message ${
                  formStatus.errors.findIndex(
                    error => error.idref === "email"
                  ) !== -1 && " error-active"
                }`}
              >
                The email address you entered is not valid.
              </span>
              <input
                name="email"
                type="email"
                id="email"
                value={formData.email}
                onChange={handleOnChange}
                aria-required="true"
                required
              />
            </label>
          </InputField>
          <InputField>
            <label htmlFor="phone">
              Parent Phone Number <span className="required">(required)</span>
              <span
                className={`error-message ${
                  formStatus.errors.findIndex(
                    error => error.idref === "phone"
                  ) !== -1 && " error-active"
                }`}
              >
                You must provide a valid phone number.
              </span>
              <input
                name="phone"
                type="text"
                id="phone"
                value={formData.phone}
                onChange={handleOnChange}
                aria-required="true"
                required
              />
            </label>
          </InputField>
          <InputField>
            <label htmlFor="childName">
              Child Name <span className="required">(required)</span>
              <span
                className={`error-message ${
                  formStatus.errors.findIndex(
                    error => error.idref === "childName"
                  ) !== -1 && " error-active"
                }`}
              >
                You must provide a name for your child.
              </span>
              <input
                type="text"
                id="childName"
                name="childName"
                value={formData.childName}
                onChange={handleOnChange}
                aria-required="true"
                required
              />
            </label>
          </InputField>
          <InputField>
            <label htmlFor="childAge">
              Child Age <span className="required">(required)</span>
              <span
                className={`error-message ${
                  formStatus.errors.findIndex(
                    error => error.idref === "childAge"
                  ) !== -1 && " error-active"
                }`}
              >
                You must provide an age for your child.
              </span>
              <input
                type="text"
                name="childAge"
                id="childAge"
                value={formData.childAge}
                onChange={handleOnChange}
                aria-required="true"
                required
              />
            </label>
          </InputField>
        </fieldset>
        <fieldset>
          <legend>Child Information</legend>
          <InputField>
            <label htmlFor="childActivitiesInterested">
              What type of sport or recreation activities is your child
              interested in? <span className="required">(required)</span>
              <span
                className={`error-message ${
                  formStatus.errors.findIndex(
                    error => error.idref === "childActivitiesInterested"
                  ) !== -1 && " error-active"
                }`}
              >
                You must provide What type of sport or recreation activities is
                your child interested in.
              </span>
              <input
                type="text"
                name="childActivitiesInterested"
                id="childActivitiesInterested"
                value={formData.childActivitiesInterested}
                onChange={handleOnChange}
                aria-required="true"
                required
              />
            </label>
          </InputField>
          <InputField>
            <label htmlFor="childPastPro">
              What type of program(s) has your child participated in the past?{" "}
              <span className="required">(required)</span>
              <span
                className={`error-message ${
                  formStatus.errors.findIndex(
                    error => error.idref === "childPastPro"
                  ) !== -1 && " error-active"
                }`}
              >
                You must provide What type of programs has your child
                participated in the past.
              </span>
              <input
                type="text"
                name="childPastPro"
                id="childPastPro"
                value={formData.childPastPro}
                onChange={handleOnChange}
                aria-required="true"
                required
              />
            </label>
          </InputField>

          <InputField>
            <label htmlFor="childDisabilities">
              What disabilities does your child experience?{" "}
              <span className="required">(required)</span>
              <span
                className={`error-message ${
                  formStatus.errors.findIndex(
                    error => error.idref === "childDisabilities"
                  ) !== -1 && " error-active"
                }`}
              >
                You must provide What disabilities does your child experience.
              </span>
              <textarea
                type="text"
                name="childDisabilities"
                id="childDisabilities"
                value={formData.childDisabilities}
                onChange={handleOnChange}
                rows="5"
                aria-required="true"
                required
              />
            </label>
          </InputField>

          <InputField>
            <label htmlFor="childAdaptations">
              What adaptations does your child need in order to be successful in
              an active environment? We will expand on this in our next steps,
              but please provide some general information to get us started.{" "}
              <span className="required">(required)</span>
              <span
                className={`error-message ${
                  formStatus.errors.findIndex(
                    error => error.idref === "childAdaptations"
                  ) !== -1 && " error-active"
                }`}
              >
                You must provide What adaptations does your child need in order
                to be successful.
              </span>
              <textarea
                type="text"
                name="childAdaptations"
                id="childAdaptations"
                value={formData.childAdaptations}
                onChange={handleOnChange}
                rows="5"
                aria-required="true"
                required
              />
            </label>
          </InputField>

          <InputField>
            <label htmlFor="childAide">
              Does your child require a dedicated aide or have any medical
              considerations? <span className="required">(required)</span>
              <span
                className={`error-message ${
                  formStatus.errors.findIndex(
                    error => error.idref === "childAide"
                  ) !== -1 && " error-active"
                }`}
              >
                You must provide if your child requires a dedicated aide or have
                any medical considerations.
              </span>
              <input
                type="text"
                name="childAide"
                id="childAide"
                value={formData.childAide}
                onChange={handleOnChange}
                aria-required="true"
                required
              />
            </label>
          </InputField>
          <CheckboxField>
            <legend>
              The Calgary Adapted Hub (CAH) powered by Jumpstart works with a
              research team to understand the impact of programs have on
              physical activity, participation, quality of life, and overall
              health and well-being to inform the future of adaptive and
              inclusive programs. Are you willing to be contacted by the CAH
              Research and Knowledge Translation Coordinator or a qualified
              research staff member completing research on behalf of the CAH?
            </legend>
            <ul role="group">
              <li>
                <label htmlFor="iagree">
                  <span
                    className={`error-message ${
                      formStatus.errors.findIndex(
                        error => error.idref === "iagree"
                      ) !== -1
                        ? " error-active"
                        : ""
                    }`}
                  >
                    you must agree before submitting form.
                  </span>
                  <input
                    id="iagree"
                    required=""
                    type="checkbox"
                    name="consent"
                    value="iagree"
                    onChange={handleOnCheckbox}
                    checked={formData.iagree}
                  />
                  Yes, I agree to be contacted by Calgary Adapted Hub
                </label>
              </li>
            </ul>
          </CheckboxField>
        </fieldset>
        <fieldset>
          <legend>Spam Check</legend>
          <InputField>
            <label htmlFor="animal">
              Which animal is larger, a mouse or a horse?{" "}
              <span className="required">(required)</span>
              <span
                className={`error-message ${
                  formStatus.errors.findIndex(
                    error => error.idref === "animal"
                  ) !== -1 && " error-active"
                }`}
              >
                You must input an answer.
              </span>
              <input
                name="animal"
                type="text"
                value={formData.animal.toLowerCase()}
                id="animal"
                onChange={handleOnChange}
                aria-required="true"
                required
                style={{ textTransform: "lowercase" }}
              />
            </label>
          </InputField>
        </fieldset>
        <SubmitButton>
          <button type="submit">Submit</button>
        </SubmitButton>
      </Form>
      <FormSubmit isActive={formStatus.submitting} />
      <FormSuccess
        isActive={formStatus.success}
        handleClose={handleSuccessModalClose}
      />
      <FormErrors
        isActive={formStatus.errorWarnDisplay}
        handleClose={handleErrorModalClose}
      />
    </>
  )
}

const Form = styled.form`
  width: 100%;
  margin-bottom: 2.5rem;

  fieldset {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
  }

  legend {
    ${B1Blue};
  }
`

const CheckboxField = styled.div`
  ${checkboxField};
  padding-bottom: 5rem;

  legend {
    padding-bottom: 2.5rem;
  }
`

const InputField = styled.div`
  width: 100%;
  margin: 1rem auto;
  padding: 1rem 0;

  label {
    ${Nav1Blue};
    display: block;
    width: 100%;
    line-height: 1.5;

    &:hover {
      color: ${colors.colorPrimary};
      cursor: initial;
    }

    .error-message {
      display: none;
    }

    .error-active {
      display: inline-block;
      color: red;
      padding-left: 2rem;
    }

    input,
    textarea {
      display: block;
      margin-top: 0.25rem;
      margin-bottom: 0.5rem;
      padding: 0.9rem 1rem;
      border-radius: 0.2rem;
      color: #444;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      border: 0.1rem ${colors.colorSecondary} solid;
    }
  }
`

const SubmitButton = styled.div`
  button {
    ${Btn1Gold};
  }
`

export default FamilyAssessment
