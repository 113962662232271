import React from "react"
import styled from "styled-components"
import { B1Blue, H1Blue, standardWrapper } from "../../styles/helpers"
import ElementTag from "../../utils/ElementTag"

const CentreAlignedTitleAndContent = ({ data }) => {
  const { title, content, titleTag } = data
  const mainTitleDisplay = ElementTag(titleTag, title)
  return (
    <CentreAlignedTitleAndContentStyled>
      <div className="wrapper">
        <div className="title">{mainTitleDisplay}</div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </CentreAlignedTitleAndContentStyled>
  )
}

const CentreAlignedTitleAndContentStyled = styled.section`
  padding: 3.5rem 0;
  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h1,
    h2,
    h3 {
      ${H1Blue};
    }
  }

  .content {
    width: 100%;
    text-align: center;

    p {
      ${B1Blue};
    }
  }
`

export default CentreAlignedTitleAndContent
