import React, { useState } from "react"
import styled from "styled-components"
import { B1Blue, Btn1Gold } from "../../styles/helpers"

import inputField from "./styles/inputField"
import textareaField from "./styles/textareaField"

import submitToServer from "./functions/submitToServer"
import FormSuccess from "./formModals/FormSuccess"
import FormSubmit from "./formModals/FormSubmit"
import FormErrors from "./formModals/FormErrors"

const VolunteerForm = () => {
  const [formData, setFormData] = useState({
    yourName: "",
    phone: "",
    email: "",
    interest: "",
  })

  const [formStatus, setFormStatus] = useState({
    submitting: false,
    errorWarnDisplay: false,
    success: false,
    errors: [],
  })

  const handleOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = async event => {
    event.preventDefault()
    setFormStatus({
      ...formStatus,
      submitting: true,
    })
    const formDataArray = Object.entries(formData)
    const bodyFormData = new FormData()
    formDataArray.forEach(field => {
      bodyFormData.append(field[0], field[1])
    })

    const response = await submitToServer(544, bodyFormData)

    if (!response.errors) {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: false,
        success: true,
        errors: [],
      })
    } else {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: true,
        success: false,
        errors: response.errorMessages,
      })
    }
  }

  const handleErrorModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
    })
  }

  const handleSuccessModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
      errors: [],
    })

    setFormData({
      yourName: "",
      phone: "",
      email: "",
      interest: "",
    })
  }

  return (
    <>
      <Form onSubmit={handleOnSubmit}>
        <fieldset>
          <legend id="legend-1">Your Contact Information</legend>
          <InputField>
            <label htmlFor="yourName">
              Your Name <span className="required">(required)</span>
              <span
                className={`error-message${
                  formStatus.errors.findIndex(
                    error => error.idref === "yourName"
                  ) !== -1
                    ? " error-active"
                    : ""
                }`}
              >
                You must input a Name.
              </span>
              <input
                name="yourName"
                type="text"
                value={formData.yourName}
                id="yourName"
                onChange={handleOnChange}
                // aria-required="true"
                // required
              />
            </label>
          </InputField>
          <InputField>
            <label htmlFor="phone">
              Phone Number <span className="required">(required)</span>
              <span
                className={`error-message${
                  formStatus.errors.findIndex(
                    error => error.idref === "phone"
                  ) !== -1
                    ? " error-active"
                    : ""
                }`}
              >
                You must input a phone number.
              </span>
              <input
                name="phone"
                type="text"
                value={formData.phone}
                id="phone"
                onChange={handleOnChange}
                // aria-required="true"
                // required
              />
            </label>
          </InputField>
          <InputField>
            <label htmlFor="email">
              Email <span className="required">(required)</span>
              <span
                className={`error-message${
                  formStatus.errors.findIndex(
                    error => error.idref === "email"
                  ) !== -1
                    ? " error-active"
                    : ""
                }`}
              >
                You must input an email.
              </span>
              <input
                name="email"
                type="email"
                value={formData.email}
                id="email"
                onChange={handleOnChange}
                // aria-required="true"
                // required
              />
            </label>
          </InputField>
        </fieldset>
        <fieldset>
          <legend id="legend-2">Your Areas of Interest</legend>
          <TextareaField>
            <label htmlFor="interest">
              Areas of Interest <span className="required">(required)</span>
              <span
                className={`error-message${
                  formStatus.errors.findIndex(
                    error => error.idref === "interest"
                  ) !== -1
                    ? " error-active"
                    : ""
                }`}
              >
                You must input an email.
              </span>
              <textarea
                name="interest"
                value={formData.interest}
                id="interest"
                onChange={handleOnChange}
                rows={6}
                // aria-required="true"
                // required
              />
            </label>
          </TextareaField>
        </fieldset>
        <SubmitButton>
          <button type="submit">Submit</button>
        </SubmitButton>
      </Form>
      <FormSubmit isActive={formStatus.submitting} />
      <FormSuccess
        isActive={formStatus.success}
        handleClose={handleSuccessModalClose}
      />
      <FormErrors
        isActive={formStatus.errorWarnDisplay}
        handleClose={handleErrorModalClose}
      />
    </>
  )
}

const Form = styled.form`
  width: 100%;
  margin-bottom: 2.5rem;

  .privacyStatement {
    p {
      ${B1Blue};
    }
  }

  fieldset {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
  }

  legend {
    ${B1Blue};
  }
`

const InputField = styled.div`
  ${inputField};
`

const TextareaField = styled.div`
  ${textareaField};
`

const SubmitButton = styled.div`
  button {
    ${Btn1Gold};
  }
`

export default VolunteerForm
