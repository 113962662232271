import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  standardWrapper,
  H1Blue,
  H3Blue,
  B1Blue,
  colors,
  Btn1Gold,
} from "../../styles/helpers"
import { Link } from "gatsby"

import IconTwo from "../Icons/IconTwo"

const ContentWithIconsBlocks = ({ data }) => {
  const { mainTitle, contentBlocks } = data
  return (
    <ContentWithIconsBlocksStyled>
      <div className="wrapper">
        <div className="title">
          <h2>{mainTitle}</h2>
        </div>
      </div>
      <div className="blocks">
        {contentBlocks.map((block, index) => {
          const icon = getImage(
            block.icon.localFile.childImageSharp.gatsbyImageData
          )
          return (
            <Block key={index} bgcolor={index % 2 !== 0}>
              <div className="blockWrap">
                <div className="blockTitle">
                  <div className="blockTitle__icon">
                    <GatsbyImage
                      image={icon}
                      alt={block.icon.altText}
                      layout="fixed"
                    />
                  </div>
                  <div className="blockTitle__title">
                    <h3>{block.titleUnderIcon}</h3>
                  </div>
                </div>
                <div className="blockContent">
                  <div dangerouslySetInnerHTML={{ __html: block.content }} />
                  {block.buttonRequired && (
                    <div className="blockContent__link">
                      <Link to={`/${block.buttonSlug}`}>
                        {block.buttonText}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              {index % 2 !== 0 && (
                <div className="iconGraphic">
                  <IconTwo />
                </div>
              )}
            </Block>
          )
        })}
      </div>
    </ContentWithIconsBlocksStyled>
  )
}

const ContentWithIconsBlocksStyled = styled.section`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;

  .wrapper {
    ${standardWrapper};
    max-width: 80rem !important;
  }

  .title {
    h2 {
      ${H1Blue};
    }
  }

  .blocks {
    width: 100%;
  }
`

const Block = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  padding: 5rem 0;
  background-color: ${props =>
    props.bgcolor ? colors.colorPrimary : colors.white};

  .blockWrap {
    ${standardWrapper};
    max-width: 80rem !important;
  }

  .blockTitle {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(35%);
      padding-right: 2rem;
    }

    &__icon {
      width: 10rem;
      margin: 2rem auto;
    }

    h3 {
      ${H3Blue};
      text-align: center;
      text-transform: uppercase;
      color: ${props => (props.bgcolor ? colors.white : colors.colorPrimary)};
    }
  }

  .blockContent {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(65%);
      border-left: 0.3rem solid ${colors.colorSecondary};
      padding: 2rem;
    }

    p {
      ${B1Blue};
      color: ${props => (props.bgcolor ? colors.white : colors.colorPrimary)};
    }

    p:last-of-type {
      margin: 0;
    }

    &__link {
      margin-top: 2.5rem;

      a {
        ${Btn1Gold};
      }
    }
  }

  .iconGraphic {
    position: absolute;
    top: -3rem;
    left: 0;
    width: 16.5rem;
    z-index: 25;
  }
`

export default ContentWithIconsBlocks
