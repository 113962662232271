import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import BGImg from "gatsby-background-image"
import {
  Btn1Gold,
  colors,
  H1White,
  H2Orange,
  H2White,
  standardWrapper,
} from "../../styles/helpers"

import IconOne from "../Icons/IconOne"

const HeroContent = ({ data }) => {
  const {
    title,
    subTitle,
    content,
    buttonRequired,
    buttonSlug,
    buttonText,
    secondButtonRequired,
    secondButtonText,
    secondButtonFile,
    backgroundImage,
  } = data

  const heroImage = backgroundImage.localFile.childImageSharp.fluid
  const heroImageAlt = backgroundImage.altText

  return (
    <HeroContentStyled>
      <div className="wrapper">
        <div className="heroContent">
          <div className="heroContent__titles">
            <h1>{title}</h1>
            <h3>{subTitle}</h3>
          </div>
          <div
            className="heroContent__content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {buttonRequired && (
            <div className="heroContent__link">
              <Link to={`/${buttonSlug}`}>{buttonText}</Link>
            </div>
          )}
          {secondButtonRequired && (
            <div className="heroContent__link">
              <a
                target="_blank"
                rel="noreferrer"
                href={secondButtonFile.mediaItemUrl}
              >
                {secondButtonText}
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="heroImage">
        <BGImg tag="div" fluid={heroImage} alt={""} />
      </div>
      <div className="heroOverlay" />
      <div className="iconGraphic">
        <IconOne />
      </div>
    </HeroContentStyled>
  )
}

const HeroContentStyled = styled.section`
  position: relative;
  padding: 5rem 0;

  .wrapper {
    ${standardWrapper};
    position: relative;
    max-width: 85rem;
    z-index: 100;
  }

  .heroContent {
    width: 100%;

    &__titles {
      h1 {
        ${H1White};
      }

      h3 {
        ${H2Orange};
      }
    }

    &__content {
      p {
        ${H2White};
      }
    }

    &__link {
      width: 100%;
      margin-bottom: 1.5rem;

      a {
        ${Btn1Gold};
      }
    }
  }

  .heroImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    div {
      width: 100%;
      height: 100%;
    }
  }

  .heroOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.colorPrimary};
    opacity: 0.9;
    z-index: 5;
  }

  .iconGraphic {
    position: absolute;
    top: 0;
    right: 0;
    width: 12.5rem;
    z-index: 25;
  }
`

export default HeroContent
