import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H1Blue, standardWrapper } from "../../styles/helpers"

import BackgroundPatternOne from "../Graphics/BackgroundPatternOne"

const LogosLinks = ({ data }) => {
  const { title, logos, graphicRequired } = data
  return (
    <LogosLinksStyled graphic={graphicRequired}>
      <div className="wrapper">
        {title !== null && (
          <div className="title">
            <h2>{title}</h2>
          </div>
        )}
        <div className="logos">
          {logos.map((logo, index) => {
            const logoDisplay = getImage(
              logo.logo.localFile.childImageSharp.gatsbyImageData
            )
            const logoAltText = logo.logo.altText

            return (
              <Logo key={index}>
                {logo.linkRequired ? (
                  <a href={logo.linkUrl}>
                    <GatsbyImage
                      image={logoDisplay}
                      alt={logoAltText}
                      layout="fixed"
                    />
                    <span className="visuallyhidden">
                      {logo.linkVisualAidText}
                    </span>
                  </a>
                ) : (
                  <>
                    <GatsbyImage
                      image={logoDisplay}
                      alt={logoAltText}
                      layout="fixed"
                    />
                  </>
                )}
              </Logo>
            )
          })}
        </div>
      </div>
      {graphicRequired && (
        <div className="backgroundPattern">
          <BackgroundPatternOne />
        </div>
      )}
    </LogosLinksStyled>
  )
}

const LogosLinksStyled = styled.section`
  position: relative;
  padding: 3rem 0 10rem;
  padding-bottom: ${props => (props.graphic ? "10rem" : "2.5rem")};

  .wrapper {
    ${standardWrapper};
    padding-bottom: 0;
  }
  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${H1Blue};
    }
  }

  .logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .backgroundPattern {
    position: absolute;
    top: 65%;
    right: 0;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
    z-index: -1;

    @media (min-width: 768px) {
      top: 60%;
    }
  }
`

const Logo = styled.div`
  align-self: center;
  width: calc((100 / 2 * 1%) - 4rem);
  margin: 1rem 2rem;

  @media (min-width: 768px) {
    width: calc((100 / 3 * 1%) - 4rem);
  }

  @media (min-width: 1025px) {
    width: calc((100 / 4 * 1%) - 6rem);
    margin: 1rem 3rem;
  }

  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`

export default LogosLinks
