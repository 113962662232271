import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H1Blue, H2Blue, standardWrapper } from "../../styles/helpers"
import ElementTag from "../../utils/ElementTag"
import SimpleWysiwyg from "./SimpleWysiwyg"

const WysiwygImageTitles = ({ data }) => {
  const { title, subTitleTag, subTitle, titleTag, image, wysiwyg } = data
  const mainTitleDisplay = ElementTag(titleTag, title)
  const subTitleDisplay = ElementTag(subTitleTag, subTitle)
  const imageDisplay = getImage(image.localFile.childImageSharp.gatsbyImageData)
  const imageAlt = image.altText

  return (
    <WysiwygImageTitlesStyled>
      <div className="wrapper">
        <div className="mainTitle">
          <div className="mainTitle__title">{mainTitleDisplay}</div>
          <div className="mainTitle__sub">{subTitleDisplay}</div>
        </div>
        <div className="content">
          <div className="content__image">
            <GatsbyImage image={imageDisplay} alt={imageAlt} layout="fixed" />
          </div>
          <div className="content__wysiwygContent">
            <SimpleWysiwyg data={{ wysiwygContent: wysiwyg }} />
          </div>
        </div>
      </div>
    </WysiwygImageTitlesStyled>
  )
}

const WysiwygImageTitlesStyled = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .mainTitle {
    width: 100%;
    max-width: 70rem;
    text-align: center;

    &__title {
      h1,
      h2,
      h3 {
        ${H1Blue};
      }
    }

    &__sub {
      h1,
      h2,
      h3,
      h4,
      p {
        ${H2Blue};
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 5rem;

    &__image {
      width: calc(100%);
      max-width: 25rem;
      margin: auto;

      @media (min-width: 768px) {
        width: calc(30%);
        max-width: 100%;
      }
    }

    &__wysiwygContent {
      width: calc(100%);

      @media (min-width: 768px) {
        width: calc(70%);
        padding-left: 5rem;
      }
    }
  }
`

export default WysiwygImageTitles
