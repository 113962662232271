import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  B1White,
  Btn1Gold,
  colors,
  H1White,
  standardWrapper,
} from "../../styles/helpers"

const BannerCallout = ({ data }) => {
  const {
    title,
    content,
    buttonRequired,
    buttonSlug,
    buttonText,
    backgroundColor,
  } = data
  return (
    <BannerCalloutStyled bgcolor={backgroundColor}>
      <div className="wrapper">
        <div className="title">
          <h2>{title}</h2>
        </div>
        {!!content && (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        {buttonRequired && (
          <div className="buttonLink">
            <Link to={`/${buttonSlug}`}>{buttonText}</Link>
          </div>
        )}
      </div>
    </BannerCalloutStyled>
  )
}

const BannerCalloutStyled = styled.section`
  background-color: ${props =>
    props.bgcolor === "blue"
      ? colors.colorPrimary
      : props.bgcolor === "lightblue"
      ? colors.colorSecondary
      : colors.colorPrimary};
  padding: 5rem 0;

  .wrapper {
    ${standardWrapper};
    max-width: 80rem;
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${H1White};
    }
  }

  .content {
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;
    p {
      ${B1White};
    }
  }

  .buttonLink {
    width: 100%;
    text-align: center;

    a {
      ${Btn1Gold};
    }
  }
`

export default BannerCallout
