import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { B1Blue, colors, H3Blue, standardWrapper } from "../../styles/helpers"

const getData = graphql`
  {
    members: allWpCommunityAdvisoryMember {
      edges {
        node {
          acfCommunityAdvisoryCommittee {
            bio
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750)
                }
              }
            }
          }
          title
        }
      }
    }
  }
`

const CommunityAdvisoryCommittee = ({ data }) => {
  const dataMembers = useStaticQuery(getData)
  const members = dataMembers.members.edges
  const { showTheCommunityAdvisoryCommittee } = data
  if (!showTheCommunityAdvisoryCommittee) return null
  return (
    <CommunityAdvisoryCommitteeStyled>
      <div className="wrapper">
        {members.map((member, index) => {
          const imageDisplay = getImage(
            member.node.acfCommunityAdvisoryCommittee.image.localFile
              .childImageSharp.gatsbyImageData
          )
          const imageAlt =
            member.node.acfCommunityAdvisoryCommittee.image.altText
          return (
            <div className="member" key={index}>
              <div className="image">
                <div className="image__wrapper">
                  <GatsbyImage
                    image={imageDisplay}
                    alt={imageAlt}
                    layout="fixed"
                  />
                </div>
              </div>
              <div className="bio">
                <div>
                  <h3>{member.node.title}</h3>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: member.node.acfCommunityAdvisoryCommittee.bio,
                  }}
                />
              </div>
            </div>
          )
        })}
      </div>
    </CommunityAdvisoryCommitteeStyled>
  )
}

const CommunityAdvisoryCommitteeStyled = styled.div`
  .wrapper {
    ${standardWrapper};
  }

  .member {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 2.5rem;

    .image {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(15%);
      }

      &__wrapper {
        width: 12.5rem;
        height: 12.5rem;
        padding: 0;
        border-radius: 50%;
        overflow: hidden;

        @media (min-width: 768px) {
          margin: auto;
        }
      }
    }

    .bio {
      width: 100%;

      border-bottom: solid 0.3rem ${colors.colorSecondary};

      @media (min-width: 768px) {
        width: calc(85% - 2.5rem);
        margin-left: 2.5rem;
      }

      h3 {
        ${H3Blue};
        text-transform: uppercase;
      }

      p {
        ${B1Blue};
        margin-bottom: 2.5rem;
      }
    }
  }
`

export default CommunityAdvisoryCommittee
