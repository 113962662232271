import React from "react"

import HeroContent from "./PageComponents/HeroContent"
import HeroContentTwo from "./PageComponents/HeroContentTwo"
import HeroContentThree from "./PageComponents/HeroContentThree"
import IconLinks from "./PageComponents/IconLinks"
import SideBySideContentBlocks from "./PageComponents/SideBySideContentBlocks"
import ContentBesideSmallImage from "./PageComponents/ContentBesideSmallImage"
import ContentWithIconsBlocks from "./PageComponents/ContentWithIconsBlocks"
import LogosLinks from "./PageComponents/LogosLinks"
import ImageByContent from "./PageComponents/ImageByContent"
import SideBySideImageOverContent from "./PageComponents/SideBySideImageOverContent"
import ContentBySmallImage from "./PageComponents/ContentBySmallImage"
import BannerCallout from "./PageComponents/BannerCallout"
import ContentWithImageEven from "./PageComponents/ContentWithImageEven"
import ContentWithImageSeventy from "./PageComponents/ContentWithImageSeventy"
import ContentThenColumnsSection from "./PageComponents/ContentThenColumnsSection"
import CommunityAdvisoryCommittee from "./PageComponents/CommunityAdvisoryCommittee"
import ImageContentWithSocialLinks from "./PageComponents/ImageContentWithSocialLinks"
import ProgramsCategoriesNavigation from "./PageComponents/ProgramsCategoriesNavigation"
import BannerCalloutIcon from "./PageComponents/BannerCalloutIcon"
import BannerCalloutNoText from "./PageComponents/BannerCalloutNoText"
import SimpleTitleWithContent from "./PageComponents/SimpleTitleWithContent"
import ProcessSteps from "./PageComponents/ProcessSteps"
import WysiwygWithHeaderAndTitles from "./PageComponents/WysiwygWithHeaderAndTitles"
import FamilyAssessmentForm from "./PageComponents/FamilyAssessmentForm"
import HeroJustAnImage from "./PageComponents/HeroJustAnImage"
import LightBlueTitleBesideImage from "./PageComponents/LightBlueTitleBesideImage"
import CentreAlignedTitleAndContent from "./PageComponents/CentreAlignedTitleAndContent"
import ResearchConsentContactForm from "./PageComponents/ResearchConsentContactForm"
import MailingList from "./PageComponents/MailingList"
import VolunteerSignUpForm from "./PageComponents/VolunteerSignUpForm"
import CircleImageByContent from "./PageComponents/CircleImageByContent"
import MediaArticles from "./PageComponents/MediaArticles"
import Wysiwyg from "./PageComponents/Wysiwyg"
import LastestNewsArticle from "./PageComponents/LastestNewsArticle"
import PointsSection from "./PageComponents/PointsSection"
import OurStaff from "./PageComponents/OurStaff"
import WysiwygImageTitles from "./PageComponents/WysiwygImageTitles"
import ImageBoldContent from "./PageComponents/ImageBoldContent"
import ImageWithList from "./PageComponents/ImageWithList"
import JobPosting from "./PageComponents/JobPosting"

const PageComponentGroups = props => {
  const { components } = props
  const allPageComponents =
    components?.acfMainTemplateFields?.pageComponents?.length > 0 ? (
      <>
        {components?.acfMainTemplateFields?.pageComponents.map(
          (component, index) => {
            switch (component?.fieldGroupName) {
              case "page_Acfmaintemplatefields_PageComponents_HeroContent":
                return <HeroContent key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_HeroContentTwo":
                return <HeroContentTwo key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_HeroContentThree":
                return <HeroContentThree key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_IconLinks":
                return <IconLinks key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_SideBySideContentBlocks":
                return <SideBySideContentBlocks key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_ContentBesideSmallImage":
                return <ContentBesideSmallImage key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_ContentWithIconsBlocks":
                return <ContentWithIconsBlocks key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_LogosLinks":
                return <LogosLinks key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_ImageByContent":
                return <ImageByContent key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_SideBySideImageOverContent":
                return (
                  <SideBySideImageOverContent key={index} data={component} />
                )
              case "page_Acfmaintemplatefields_PageComponents_ContentBySmallImage":
                return <ContentBySmallImage key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_BannerCallout":
                return <BannerCallout key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_ContentWithImageEven":
                return <ContentWithImageEven key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_ContentWithImageSeventy":
                return <ContentWithImageSeventy key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_ContentThenColumnsSection":
                return (
                  <ContentThenColumnsSection key={index} data={component} />
                )

              case "page_Acfmaintemplatefields_PageComponents_CommunityAdvisoryCommittee":
                return (
                  <CommunityAdvisoryCommittee key={index} data={component} />
                )
              case "page_Acfmaintemplatefields_PageComponents_ImageContentWithSocialLinks":
                return (
                  <ImageContentWithSocialLinks key={index} data={component} />
                )
              case "page_Acfmaintemplatefields_PageComponents_ProgramsCategoriesNavigation":
                return (
                  <ProgramsCategoriesNavigation key={index} data={component} />
                )
              case "page_Acfmaintemplatefields_PageComponents_BannerCalloutIcon":
                return <BannerCalloutIcon key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_SimpleTitleWithContent":
                return <SimpleTitleWithContent key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_ProcessSteps":
                return <ProcessSteps key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_BannerCalloutNoText":
                return <BannerCalloutNoText key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_WysiwygWithHeaderAndTitles":
                return (
                  <WysiwygWithHeaderAndTitles key={index} data={component} />
                )

              case "page_Acfmaintemplatefields_PageComponents_FamilyAssessmentForm":
                return <FamilyAssessmentForm key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_HeroJustAnImage":
                return <HeroJustAnImage key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_LightBlueTitleBesideImage":
                return (
                  <LightBlueTitleBesideImage key={index} data={component} />
                )

              case "page_Acfmaintemplatefields_PageComponents_CentreAlignedTitleAndContent":
                return (
                  <CentreAlignedTitleAndContent key={index} data={component} />
                )

              case "page_Acfmaintemplatefields_PageComponents_ResearchConsentContactForm":
                return (
                  <ResearchConsentContactForm key={index} data={component} />
                )

              case "page_Acfmaintemplatefields_PageComponents_MailingList":
                return <MailingList key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_VolunteerSignUpForm":
                return <VolunteerSignUpForm key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_CircleImageByContent":
                return <CircleImageByContent key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_DisplayNewAndMediaArticles":
                return <MediaArticles key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_Wysiwyg":
                return <Wysiwyg key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_DisplayLatestNewsArticle":
                return <LastestNewsArticle key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_PointsSection":
                return <PointsSection key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_WysiwygImageTitles":
                return <WysiwygImageTitles key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_ImageBoldContent":
                return <ImageBoldContent key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_ImageWithList":
                return <ImageWithList key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_OurStaff":
                return <OurStaff key={index} data={component} />

              case "page_Acfmaintemplatefields_PageComponents_JobPosting":
                return <JobPosting key={index} data={component} />

              default:
                return (
                  <p>Cannot find this component {component.fieldGroupName}</p>
                )
            }
          }
        )}
      </>
    ) : (
      <p>This page has no content</p>
    )

  return <>{allPageComponents}</>
}

export default PageComponentGroups
