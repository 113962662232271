import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1Blue,
  colors,
  H1Blue,
  H3Blue,
  standardWrapper,
} from "../../styles/helpers"

const OurStaff = ({ data }) => {
  const { title, teamMembers } = data
  return (
    <OurStaffStyled>
      <div className="wrapper">
        <div className="mainTitle">
          <h2>{title}</h2>
        </div>
        <div>
          {teamMembers.map((member, index) => {
            const imageDisplay = getImage(
              member.image.localFile.childImageSharp.gatsbyImageData
            )
            const imageAlt = member.image.altText
            return (
              <Member key={index}>
                <div className="image">
                  <div className="image__wrapper">
                    <GatsbyImage
                      image={imageDisplay}
                      alt={imageAlt}
                      layout="fixed"
                    />
                  </div>
                </div>
                <div className="bio">
                  <div>
                    <h3>{member.nameTitle}</h3>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: member.bio }} />
                </div>
              </Member>
            )
          })}
        </div>
      </div>
    </OurStaffStyled>
  )
}

const OurStaffStyled = styled.section`
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #f5f5f5;

  .wrapper {
    ${standardWrapper};
  }

  .mainTitle {
    width: 100%;
    padding-left: 20%;

    h2 {
      ${H1Blue};
    }
  }
`

const Member = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2.5rem;
  border-bottom: 0.25rem solid ${colors.colorSecondary};

  .image {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(20%);
    }

    &__wrapper {
      width: 15rem;
      height: 15rem;
      margin: auto;
      padding: 0;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .bio {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(80%);
    }

    h3 {
      ${H3Blue};
    }

    p {
      ${B1Blue};
    }
  }
`

export default OurStaff
