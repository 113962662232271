import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1Blue, medWrapper, Btn1Gold } from "../../styles/helpers"
import BackgroundPatternOne from "../Graphics/BackgroundPatternOne"

const ImageByContent = ({ data }) => {
  const {
    content,
    image,
    topGraphicActive,
    buttonRequired,
    buttonText,
    buttonSlug,
    secondButtonRequired,
    secondButtonText,
    secondButtonType,
    secondButtonInternalSlug,
    secondButtonFile,
    secondButtonExternalUrl,
    thirdButtonRequired,
    thirdButtonType,
    thirdButtonExternalUrl,
    thirdButtonInternalSlug,
    thirdButtonFile,
    thirdButtonText,
    fourthButtonRequired,
    fourthButtonType,
    fourthButtonExternalUrl,
    fourthButtonInternalSlug,
    fourthButtonFile,
    fourthButtonText,
  } = data
  const imageDisplay = getImage(image.localFile.childImageSharp.gatsbyImageData)
  const imageAlt = image.altText

  return (
    <ImageByContentStyled graphicactive={topGraphicActive}>
      <div className="wrapper">
        <div className="image">
          <GatsbyImage image={imageDisplay} alt={imageAlt} layout="fixed" />
        </div>
        <div className="content">
          <div
            className="content__para"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {buttonRequired && (
            <div className="buttonLink">
              <Link to={`/${buttonSlug}`}>{buttonText}</Link>
            </div>
          )}
          {secondButtonRequired && (
            <div className="buttonLink buttonLink-two">
              {secondButtonType === "external" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={secondButtonExternalUrl}
                >
                  {secondButtonText}
                </a>
              ) : secondButtonType === "internal" ? (
                <Link to={`/${secondButtonInternalSlug}`}>
                  {secondButtonText}
                </Link>
              ) : secondButtonType === "file" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={secondButtonFile.mediaItemUrl}
                >
                  {secondButtonText}
                </a>
              ) : null}
            </div>
          )}
          {thirdButtonRequired && (
            <div className="buttonLink buttonLink-two">
              {thirdButtonType === "external" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={thirdButtonExternalUrl}
                >
                  {thirdButtonText}
                </a>
              ) : thirdButtonType === "internal" ? (
                <Link to={`/${thirdButtonInternalSlug}`}>
                  {thirdButtonText}
                </Link>
              ) : thirdButtonType === "file" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={thirdButtonFile.mediaItemUrl}
                >
                  {thirdButtonText}
                </a>
              ) : null}
            </div>
          )}

          {fourthButtonRequired && (
            <div className="buttonLink buttonLink-two">
              {fourthButtonType === "external" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={fourthButtonExternalUrl}
                >
                  {fourthButtonText}
                </a>
              ) : fourthButtonType === "internal" ? (
                <Link to={`/${fourthButtonInternalSlug}`}>
                  {fourthButtonText}
                </Link>
              ) : fourthButtonType === "file" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={fourthButtonFile.mediaItemUrl}
                >
                  {fourthButtonText}
                </a>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <div className="backgroundPattern">
        <BackgroundPatternOne />
      </div>
    </ImageByContentStyled>
  )
}

const ImageByContentStyled = styled.div`
  position: relative;
  padding-top: 10rem;
  padding-bottom: 5rem;

  .wrapper {
    ${medWrapper};
    align-items: center;
  }

  .image {
    width: 100%;

    @media (max-width: 767px) {
      max-width: 30rem;
    }

    @media (min-width: 768px) {
      width: calc(30% - 5rem);
      margin-right: 5rem;
    }
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(70%);
    }
    p {
      ${B1Blue};
    }
  }

  .buttonLink {
    width: 100%;
    text-align: left;

    &.buttonLink-two {
      margin-top: 1rem;
    }

    a {
      ${Btn1Gold};
    }
  }

  .backgroundPattern {
    display: ${props => (props.graphicactive ? "block" : "none")};
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 65%;
    z-index: -1;

    @media (min-width: 768px) {
      bottom: 60%;
    }
  }
`

export default ImageByContent
