import React from "react"
import styled from "styled-components"
import { H3Blue, standardWrapper } from "../../styles/helpers"

import VolunteerForm from "../Forms/VolunteerForm"

const VolunteerSignUpForm = ({ data }) => {
  if (data.displayVolunteerSignUpForm === null) return null
  return (
    <VolunteerSignUpFormStyled>
      <div className="wrapper">
        <div className="title">
          <h2>Become part of the Calgary Adapted Hub volunteer team</h2>
        </div>
        <div className="form">
          <VolunteerForm />
        </div>
      </div>
    </VolunteerSignUpFormStyled>
  )
}

const VolunteerSignUpFormStyled = styled.div`
  .wrapper {
    ${standardWrapper};
    max-width: 80rem !important;
  }

  .title {
    width: 100%;

    h2 {
      ${H3Blue};
    }
  }

  .form {
    width: 100%;
  }
`

export default VolunteerSignUpForm
