import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1Blue,
  medWrapper,
  Btn1Gold,
  BigWrapper,
  H2Blue,
  colors,
  B1White,
} from "../../styles/helpers"

const PointsSection = ({ data }) => {
  const { topPoints, image, bottomTitle, titleTag, bottomPoints } = data
  const imageDisplay = getImage(image.localFile.childImageSharp.gatsbyImageData)
  const imageAlt = image.altText
  return (
    <PointsSectionStyled>
      <div className="wrapperColor">
        <div className="wrapper">
          <div className="image">
            <GatsbyImage image={imageDisplay} alt={imageAlt} layout="fixed" />
          </div>
          <div className="topPoints">
            {topPoints.map((point, index) => {
              return (
                <div className="topPoint" key={index}>
                  <h3>{point.title}</h3>
                  <p>{point.content}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="bottomPoints">
        <div className="bottomPoints__wrapper">
          <div className="bottomPoints__title">
            <h2>{bottomTitle}</h2>
          </div>
          <div className="bottomPoints__points">
            {bottomPoints.map((point, index) => {
              return (
                <div className="botPoint" key={index}>
                  <h3>{point.title}</h3>
                  <p>{point.content}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </PointsSectionStyled>
  )
}

const PointsSectionStyled = styled.section`
  .wrapperColor {
    width: 100%;
    background: linear-gradient(
      to right,
      #fff 0%,
      #fff 70%,
      #f5f5f5 30%,
      #f5f5f5 100%
    );
    margin-bottom: 1rem;
  }

  .wrapper {
    ${BigWrapper};

    @media (min-width: 768px) {
      padding: 0;
    }
  }

  .image {
    width: calc(100%);
    background-color: #fff;

    @media (min-width: 768px) {
      width: calc(50%);
    }
  }

  .topPoints {
    width: calc(100%);
    background-color: #f5f5f5;

    @media (min-width: 768px) {
      width: calc(50% - 3rem);
      margin-left: 3rem;
      padding: 3rem;
    }
  }

  .topPoint {
    margin-bottom: 2rem;

    h3,
    p {
      ${H2Blue};
      margin: 0;
    }
  }

  .bottomPoints {
    width: 100%;
    background-color: ${colors.colorSecondary};

    &__wrapper {
      ${medWrapper};
    }

    &__title {
      width: 100%;
      padding: 0 6rem;

      h2 {
        ${B1White};
        text-transform: uppercase;
      }
    }

    &__points {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .botPoint {
        width: 100%;
        padding: 3rem 1rem;

        @media (min-width: 768px) {
          width: calc(50%);
          padding: 3rem 6rem;
        }

        h3,
        p {
          ${B1White};
          margin: 0;
        }
      }
    }
  }
`

export default PointsSection
