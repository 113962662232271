import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  colors,
  fonts,
  B1Blue,
  Btn1Gold,
  H1Blue,
  H2Blue,
  H3Blue,
  H4Blue,
  medWrapper,
} from "../../styles/helpers"

import SocialLinks from "./SocialLinks"
import BackgroundPatternOne from "../Graphics/BackgroundPatternOne"

import ElementTag from "../../utils/ElementTag"
import { Link } from "gatsby"

const ImageContentWithSocialLinks = ({ data }) => {
  const {
    bottomContent,
    title,
    subTitle,
    topContent,
    image,
    socialLinksRequired,
    titleIcon,
    buttonRequired,
    titleTag,
    subTitleTag,
    buttonSlug,
    buttonText,
  } = data
  const iconDisplay = getImage(
    titleIcon?.localFile?.childImageSharp?.gatsbyImageData
  )
  const iconAlt = titleIcon.altText
  const imageDisplay = getImage(
    image?.localFile?.childImageSharp?.gatsbyImageData
  )
  const imageAlt = image.altText

  const mainTitleDisplay = ElementTag(titleTag, title)
  const mainSubTitleDisplay = ElementTag(subTitleTag, subTitle)

  return (
    <ImageContentWithSocialLinksStyled>
      <div className="wrapper">
        <div className="mainContent">
          <div className="mainTitleContainer">
            <div className="mainTitle">{mainTitleDisplay}</div>
            <div className="subTitle">{mainSubTitleDisplay}</div>
            <div className="mainIcon">
              <GatsbyImage image={iconDisplay} alt={iconAlt} layout="fixed" />
            </div>
          </div>
          <div
            className="topContent"
            dangerouslySetInnerHTML={{ __html: topContent }}
          />
          <div
            className="bottomContent"
            dangerouslySetInnerHTML={{ __html: bottomContent }}
          />
          {!!buttonRequired && (
            <div className="mainButton">
              {data.buttonType === "internal" ? (
                <Link to={`/${buttonSlug}`}>{buttonText}</Link>
              ) : data.buttonType === "external" ? (
                <a target="_blank" rel="noreferrer" href={`${data.buttonUrl}`}>
                  {buttonText}
                </a>
              ) : (
                <Link to={`/${buttonSlug}`}>{buttonText}</Link>
              )}
            </div>
          )}

          {!!data.content && (
            <div
              className="content-wysiwyg"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          )}

          {socialLinksRequired && (
            <div className="socialIcons">
              <SocialLinks />
            </div>
          )}
          <div className="backgroundPattern">
            <BackgroundPatternOne />
          </div>
        </div>
        <div className="mainImage">
          <div className="image">
            <GatsbyImage image={imageDisplay} alt={imageAlt} layout="fixed" />
          </div>
        </div>
      </div>
    </ImageContentWithSocialLinksStyled>
  )
}

const ImageContentWithSocialLinksStyled = styled.section`
  .wrapper {
    ${medWrapper};
    padding-top: 0;
    flex-direction: row-reverse;
  }

  .mainContent {
    position: relative;
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(66.666666% - 4rem);
      margin-left: 4rem;
    }
  }

  .mainTitleContainer {
    position: relative;
    margin-bottom: 4rem;
    padding-top: 7rem;
    padding-left: 10rem;
  }

  .mainIcon {
    position: absolute;
    top: 8.5rem;
    left: 0;
    width: 8rem;
  }

  .mainTitle {
    h1,
    h2,
    h3 {
      ${H1Blue};
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .subTitle {
    h1,
    h2,
    h3,
    p {
      ${H2Blue};
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .topContent {
    p {
      ${H2Blue};
    }
  }

  .bottomContent {
    p {
      ${B1Blue};
    }
  }

  .mainButton {
    margin-bottom: 5rem;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }

    a {
      ${Btn1Gold};
    }
  }

  .socialIcons {
    width: 100%;
  }

  .mainImage {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(33.333333%);
    }
  }

  .backgroundPattern {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 10rem;
    z-index: -1;

    @media (min-width: 768px) {
      height: 12.5rem;
    }
  }

  .content-wysiwyg {
    width: 100%;
    max-width: 90rem;
    margin-top: 2.5rem;
    margin-right: auto;
    margin-bottom: 2.5rem;
    margin-left: auto;

    &::after {
      display: table;
      clear: both;
      content: "";
    }

    .post-edit-link {
      font-size: 1.6rem;
    }

    blockquote {
      display: block;
      width: 95%;
      margin: 1.5rem auto 2rem;
      padding: 0 2.25rem;
      border-right: 5px solid ${colors.grey};
      border-left: 5px solid ${colors.grey};
      font-size: 1.6rem;
      font-style: $italic;

      @media (min-width: 768px) {
        width: 80%;
        margin: 5em auto;
        padding: 0 3rem;
      }

      p {
        margin-bottom: 0;

        &::before,
        &::after {
          font-family: ${fonts.fontAwesome};
          color: rgba($color-secondary, 1);
        }

        &::before {
          padding-right: 0.25em;
          content: "\f10d";
        }

        &::after {
          padding-left: 0.25em;
          content: "\f10e";
        }
      }
    }

    hr {
      display: block;
      height: 0.25em;
      background-color: ${colors.colorSecondary};
    }

    ul {
      margin-bottom: 2.5rem;

      li {
        position: relative;
        margin-bottom: 0.75em;
        padding-left: 0.75em;
        font-size: 1.6rem;

        &::before {
          font-family: ${fonts.fontAwesome};
          position: absolute;
          top: 0.1em;
          left: 0;
          padding-right: 0.5em;
          color: rgba($grey, 0.75);
          content: "\f0da";
        }
      }
    }

    ol {
      margin-bottom: 2.5rem;
      font-size: 1.6rem;

      li {
        margin-bottom: 0.25rem;
        font-size: 1.6rem;
        list-style-position: inside;
        list-style-type: decimal;
      }
    }

    strong {
      font-weight: bold;
    }

    em {
      font-style: $italic;
    }

    h1 {
      ${H1Blue};
      ${props =>
        props.theme === "light" ? "color: rgba(245,245,245, 1)" : null};
    }

    h2 {
      ${H2Blue};
      ${props =>
        props.theme === "light" ? "color: rgba(245,245,245, 1)" : null};
    }

    h3 {
      ${H3Blue}
      ${props =>
        props.theme === "light" ? "color: rgba(245,245,245, 1)" : null};
    }

    h4 {
      ${H4Blue}
      ${props =>
        props.theme === "light" ? "color: rgba(245,245,245, 1)" : null};
    }

    p {
      ${B1Blue};
      ${props =>
        props.theme === "light" ? "color: rgba(245,245,245, 1)" : null};

      a {
        transition: all 0.3s;
        color: ${colors.colorSecondary};
        ${props =>
          props.theme === "light" ? "color: rgba(245,245,245, 1)" : null};
        font-weight: bold;
        font-size: 1em;

        &:hover {
          color: ${colors.colorPrimary};
          ${props =>
            props.theme === "light" ? "color: rgba(120,119,124, 1)" : null};
        }
      }
    }

    del {
      color: ${colors.colorSecondary};
    }

    /* WordPress Core */
    .alignnone {
      margin: 5px 20px 20px 0;
    }

    .aligncenter,
    div.aligncenter {
      display: block;
      margin: 2rem auto;
    }

    .alignright {
      float: right;
      margin: 5px 0 20px 20px;
    }

    .alignleft {
      float: left;
      margin: 5px 20px 20px 0;
    }

    a img.alignright {
      float: right;
      margin: 5px 0 20px 20px;
    }

    a img.alignnone {
      margin: 5px 20px 20px 0;
    }

    a img.alignleft {
      float: left;
      margin: 5px 20px 20px 0;
    }

    a img.aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .wp-caption {
      background: #fff;
      border: 1px solid #f0f0f0;
      max-width: 96%; /* Image does not overflow the content area */
      padding: 5px 3px 10px;
      text-align: center;
    }

    .wp-caption.alignnone {
      margin: 5px 20px 20px 0;
    }

    .wp-caption.alignleft {
      margin: 5px 20px 20px 0;
    }

    .wp-caption.alignright {
      margin: 5px 0 20px 20px;
    }

    .wp-caption img {
      border: 0 none;
      height: auto;
      margin: 0;
      max-width: 98.5%;
      padding: 0;
      width: auto;
    }

    .wp-caption p.wp-caption-text {
      font-size: 1.1rem;
      line-height: 17px;
      margin: 0;
      padding: 0 4px 5px;
    }

    /* Text meant only for screen readers. */
    .screen-reader-text {
      clip: rect(1px, 1px, 1px, 1px);
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
    }

    .screen-reader-text:focus {
      background-color: #f1f1f1;
      border-radius: 3px;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
      clip: auto !important;
      color: #21759b;
      display: block;
      font-size: 14px;
      font-size: 0.875rem;
      font-weight: bold;
      height: auto;
      left: 5px;
      line-height: normal;
      padding: 15px 23px 14px;
      text-decoration: none;
      top: 5px;
      width: auto;
      z-index: 100000; /* Above WP toolbar. */
    }

    img {
      width: auto;
    }

    .styled-button {
      ${Btn1Gold};
    }
  }
`

export default ImageContentWithSocialLinks
