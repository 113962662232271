import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H3Blue, medWrapper } from "../../styles/helpers"

const ImageBoldContent = ({ data }) => {
  const { content, image } = data
  const imageDisplay = getImage(image.localFile.childImageSharp.gatsbyImageData)
  const imageAlt = image.altText
  return (
    <ImageBoldContentStyled>
      <div className="wrapperColor">
        <div className="wrapper">
          <div className="image">
            <GatsbyImage image={imageDisplay} alt={imageAlt} layout="fixed" />
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </ImageBoldContentStyled>
  )
}

const ImageBoldContentStyled = styled.section`
  .wrapperColor {
    width: 100%;
    background: #f5f5f5;
    margin-bottom: 1rem;

    @media (min-width: 768px) {
      background: linear-gradient(
        to right,
        #fff 0%,
        #fff 57.5%,
        #f5f5f5 57.5%,
        #f5f5f5 100%
      );
    }

    @media (min-width: 1025px) {
      background: linear-gradient(
        to right,
        #fff 0%,
        #fff 56%,
        #f5f5f5 56%,
        #f5f5f5 100%
      );
    }
  }

  .wrapper {
    ${medWrapper};
    align-items: center;
  }

  .image {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(55%);
    }
  }

  .content {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(45%);
      padding-left: 5rem;
    }

    @media (min-width: 1025px) {
      width: calc(45%);
      padding-left: 7.5rem;
    }

    p {
      ${H3Blue};
      text-transform: uppercase;
    }

    p:last-of-type {
      margin: 0;
    }
  }
`

export default ImageBoldContent
