import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { colors, Nav1Blue } from "../../styles/helpers"

import Youtube from "../Icons/Youtube"
import Instagram from "../Icons/Instagram"
import Linkedin from "../Icons/Linkedin"
import Facebook from "../Icons/Facebook"
import Twitter from "../Icons/Twitter"

const getData = graphql`
  {
    socialIcons: wp {
      siteWideSettings {
        acfSiteWideSettings {
          linkedinUrl
          instagramUrl
          youtubeUrl
          twitterUrl
          facebookUrl
        }
      }
    }
  }
`

const SocialMedia = () => {
  const data = useStaticQuery(getData)
  return (
    <SocialMediaStyled>
      <div className="title">
        <p>Follow Us</p>
      </div>
      <ul className="socialIcons">
        <StyledIcon>
          <a
            target="_blank"
            rel="noreferrer"
            title="Follow us on Instagram - Link will open in new window"
            href={
              data.socialIcons.siteWideSettings.acfSiteWideSettings.instagramUrl
            }
          >
            <i>
              <Instagram />
              <span className="visuallyhidden">Instagram</span>
            </i>
          </a>
        </StyledIcon>
        <StyledIcon>
          <a
            title="Follow us on Linkedin - Link will open in new window"
            target="_blank"
            rel="noreferrer"
            href={
              data.socialIcons.siteWideSettings.acfSiteWideSettings.linkedinUrl
            }
          >
            <i>
              <Linkedin />
              <span className="visuallyhidden">Linkedin</span>
            </i>
          </a>
        </StyledIcon>
        <StyledIcon>
          <a
            title="Follow us on Youtube - Link will open in new window"
            target="_blank"
            rel="noreferrer"
            href={
              data.socialIcons.siteWideSettings.acfSiteWideSettings.youtubeUrl
            }
          >
            <i>
              <Youtube />
              <span className="visuallyhidden">Youtube</span>
            </i>
          </a>
        </StyledIcon>
        <StyledIcon>
          <a
            title="Follow us on Facebook - Link will open in new window"
            target="_blank"
            rel="noreferrer"
            href={
              data.socialIcons.siteWideSettings.acfSiteWideSettings.facebookUrl
            }
          >
            <i>
              <Facebook />
              <span className="visuallyhidden">Facebook</span>
            </i>
          </a>
        </StyledIcon>
        <StyledIcon>
          <a
            title="Follow us on Twitter - Link will open in new window"
            target="_blank"
            rel="noreferrer"
            href={
              data.socialIcons.siteWideSettings.acfSiteWideSettings.twitterUrl
            }
          >
            <i>
              <Twitter />
              <span className="visuallyhidden">Twitter</span>
            </i>
          </a>
        </StyledIcon>
      </ul>
    </SocialMediaStyled>
  )
}

const SocialMediaStyled = styled.div`
  display: none;
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: calc(100%);
  }

  @media (min-width: 1025px) {
    width: calc(100%);
    justify-content: flex-end;
  }

  p {
    ${Nav1Blue};
    margin: 0;
    margin-right: 1rem;
    color: ${colors.colorSecondary};
    text-transform: uppercase;

    &:hover {
      color: ${colors.colorSecondary};
    }
  }

  .title {
    width: 20%;
  }

  .socialIcons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: center;
    width: 80%;
    padding: 0;
  }
`

const StyledIcon = styled.li`
  display: inline-block;
  margin-right: 1rem;
  margin-left: 1rem;

  &:first-of-type {
    margin-left: 0;
  }

  a {
    display: block;
    position: relative;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    @media (min-width: 768px) {
      width: 3rem;
      height: 3rem;
    }
    @media (min-width: 1025px) {
      width: 4rem;
      height: 4rem;
    }

    &:focus {
      outline: 0.4rem solid #003b49;
      transition: outline-width 0.35s ease-in-out;
    }

    .visuallyhidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    svg {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: auto;
      transition: all 0.3s ease-out;
      fill: ${colors.colorSecondary};

      @media (min-width: 768px) {
        width: 3rem;
        height: 3rem;
      }
      @media (min-width: 1025px) {
        width: 4rem;
        height: 4rem;
      }

      &:hover {
        fill: ${colors.colorTertiary};
      }
    }
  }
`

export default SocialMedia
