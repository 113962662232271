import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { colors, H3Blue, H4Blue, medWrapper } from "../../styles/helpers"

const getData = graphql`
  {
    postsCats: allWpCategory {
      edges {
        node {
          slug
          name
        }
      }
    }
  }
`

const CategoriesDisplay = ({ posts, setActiveCat }) => {
  const cats = useStaticQuery(getData)
  const postsCats = cats.postsCats.edges

  const handleSetActiveCat = catSlug => {
    setActiveCat(catSlug)
  }

  return (
    <CategoriesDisplayStyled>
      <div className="wrapper">
        <div className="catSelet">
          <div className="catSelet__title">
            <h3>CATEGORIES</h3>
          </div>
          <div className="catSelet__list">
            <ul>
              <li>
                <button onClick={() => handleSetActiveCat("all")} type="button">
                  All
                </button>
              </li>
              {postsCats.map((cat, index) => {
                const hasPosts = posts.findIndex(post => {
                  const match = post.node.categories.nodes.findIndex(
                    postCat => postCat.slug === cat.node.slug
                  )
                  if (match !== -1) {
                    return true
                  }
                  return false
                })

                return (
                  <li key={index}>
                    <button
                      disabled={hasPosts === -1}
                      onClick={() => handleSetActiveCat(cat.node.slug)}
                      type="button"
                    >
                      {cat.node.name}
                    </button>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </CategoriesDisplayStyled>
  )
}

const CategoriesDisplayStyled = styled.section`
  width: 100%;
  background-color: ${colors.colorShad};

  .wrapper {
    ${medWrapper};
  }

  .catSelet {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    &__title {
      width: 100%;
      text-align: center;

      @media (min-width: 768px) {
        width: 10%;
        text-align: left;
      }

      h3 {
        ${H4Blue};
        margin: 0;
      }
    }

    &__list {
      width: 100%;

      @media (min-width: 768px) {
        width: 90%;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        li {
          margin: 1rem 0.5rem;

          @media (min-width: 768px) {
            margin: 2rem 0.5rem;
          }

          @media (min-width: 1025px) {
            margin: 0 0.5rem;
          }

          button {
            ${H3Blue};
            display: inline-block;
            padding: 0.5rem 2.5rem;
            margin: 0;
            border: none;
            border-radius: 1.9rem;
            transition: all 0.3s ease;
            text-transform: uppercase;
            cursor: pointer;

            &:focus {
              outline: 0.4rem solid #003b49;
              transition: all 0.3s ease;
            }

            &:hover {
              background: ${colors.colorTertiary};
            }

            &:disabled {
              cursor: not-allowed;
              opacity: 0.5;

              &:hover {
                background: ${colors.greyMed};
                color: ${colors.colorPrimary};
              }
            }
          }
        }
      }
    }
  }
`

export default CategoriesDisplay
