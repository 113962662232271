import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1Blue, colors, H1Blue, medWrapper } from "../../styles/helpers"

const ImageWithList = ({ data }) => {
  const { title, image, listItems } = data
  const imageDisplay = getImage(image.localFile.childImageSharp.gatsbyImageData)
  const imageAlt = image.altText
  return (
    <ImageWithListStyled>
      <div className="wrapper">
        <div className="image">
          <GatsbyImage image={imageDisplay} alt={imageAlt} layout="fixed" />
        </div>
        <div className="content">
          <h2>{title}</h2>
          <div>
            {listItems.map((item, index) => {
              return (
                <div key={index}>
                  {item.linkRequired ? (
                    <p>
                      <a href={item.link}>{item.item}</a>
                    </p>
                  ) : (
                    <p>{item.item}</p>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </ImageWithListStyled>
  )
}

const ImageWithListStyled = styled.section`
  .wrapper {
    ${medWrapper};
    align-items: center;
    flex-direction: row-reverse;
  }

  .image {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(30%);
    }
  }

  .content {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(70%);
      padding-right: 5rem;
    }

    @media (min-width: 1025px) {
      width: calc(70%);
      padding-right: 5rem;
      padding-left: 5rem;
    }
    h2 {
      ${H1Blue};
    }

    p,
    a {
      ${B1Blue};
      margin: 0;
    }

    a {
      &:hover {
        color: ${colors.colorSecondary};
      }
    }
  }
`

export default ImageWithList
