import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1Blue, H1Blue, H2Blue, medWrapper } from "../../styles/helpers"

const ProcessSteps = ({ data }) => {
  const { title, steps } = data
  return (
    <ProcessStepsStyled>
      <div className="wrapper">
        <div className="title">
          <h2>{title}</h2>
        </div>
        <div className="steps">
          {steps.map((step, index) => {
            const iconDisplay = getImage(
              step.icon.localFile.childImageSharp.gatsbyImageData
            )
            const iconAlt = step.icon.altText
            return (
              <Step key={index}>
                <div className="stepIcon">
                  <GatsbyImage
                    image={iconDisplay}
                    alt={iconAlt}
                    layout="fixed"
                  />
                </div>
                <div className="stepTitle">
                  <h3>{step.title}</h3>
                </div>
                <div
                  className="stepContent"
                  dangerouslySetInnerHTML={{ __html: step.content }}
                />
              </Step>
            )
          })}
        </div>
      </div>
    </ProcessStepsStyled>
  )
}

const ProcessStepsStyled = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${H1Blue};
    }
  }

  .steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 5rem;
  }
`

const Step = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: calc(50%);
  }

  @media (min-width: 1025px) {
    width: calc(25% - 4rem);
    margin: 1rem 2rem;
  }

  .stepIcon {
    width: 100%;
    max-width: 7.5rem;
  }

  .stepTitle {
    h3 {
      ${H2Blue};
    }
  }

  .stepContent {
    p {
      ${B1Blue};
    }
  }
`

export default ProcessSteps
