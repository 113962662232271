import React from "react"
import styled from "styled-components"
import { H1Blue, H2LightBlue, medWrapper } from "../../styles/helpers"

const SimpleTitleWithContent = ({ data }) => {
  const { title, content } = data
  return (
    <SimpleTitleWithContentStyled>
      <div className="wrapper">
        <div className="title">
          <h2>{title}</h2>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </SimpleTitleWithContentStyled>
  )
}

const SimpleTitleWithContentStyled = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${H1Blue};
    }
  }

  .content {
    width: 100%;
    text-align: center;

    p {
      ${H2LightBlue};
    }
  }
`

export default SimpleTitleWithContent
